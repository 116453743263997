import { useEffect, useState } from "react";
import { Formik, Form, FieldArray, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const ProdutogruposAddPage = (props) => {
    const app = useApp();

    //form validation rules
    const validationSchema = yup.object().shape({
        arrForm: yup.array().of(
            yup.object().shape({
                grupoid: yup.string().required().label("Grupo"),
                // proid: yup.string().required().label("Produto")
            })
        )
    });
    
    useEffect(() => {
                
    }, []);

    //form default values
    const formDefaultValues = {
        arrForm: [{
            grupoid: props.grupoid,
            proid: '',
        }]
    }

    //page hook where logics resides
    const pageController = useAddPage({ props, formDefaultValues, afterSubmit });
    

    // destructure and grab what the page needs
    const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;

    //event raised after form submit
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        resetForm();
        if (app.isDialogOpen()) {
            app.closeDialogs(); // if page is open as dialog, close dialog
        }
        else if (props.redirect) {
            app.navigate(`/produtogrupos`);
        }
    }

    // page loading form data from api
    if (loading) {
        return (
            <div className="p-3 text-center">
                <ProgressSpinner style={{ width: '50px', height: '50px' }} />
            </div>
        );
    }

    //page has loaded any required data and ready to render
    if (pageReady) {
        return (
            <main id="ProdutogruposAddPage" className="main-page">
                {(props.showHeader) &&
                    <section className="page-section mb-3" >
                        <div className="container">
                            <div className="grid justify-content-between align-items-center">
                                {!props.isSubPage &&
                                    <div className="col-fixed " >
                                        <Button onClick={() => app.navigate(-1)} 
                                        label="" 
                                        className="p-button p-button-text " 
                                        icon="pi pi-arrow-left" />
                                    </div>
                                }
                                <div className="col " >
                                    <Title title="Adicionar novo" 
                                    titleClass="text-2xl text-primary font-bold" 
                                    subTitleClass="text-500" 
                                    separator={false} />
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <section className="page-section " >
                    <div className="container">
                        <div className="grid ">
                            <div className="md:col-9 sm:col-12 comp-grid" >
                                <div >
                                    <Formik initialValues={formData} 
                                    validationSchema={validationSchema} 
                                    onSubmit={(values, actions) => submitForm(values.arrForm)}>
                                        {(formik) =>

                                            <Form>
                                                <div>        
                                                        <DataSource apiPath="components_data/grupoid_option_list"  >
                                                        {
                                                            ({ response }) =>
                                                                <>
                                                                    Selecione o Grupo
                                                                    <Dropdown 
                                                                    name={`arrForm.0.grupoid`}
                                                                    optionLabel="label" 
                                                                    optionValue="value" 
                                                                    value={props.grupoid} 
                                                                    onChange={formik.handleChange} 
                                                                    options={response}  
                                                                    label="Grupo" 
                                                                    placeholder="Selecione um valor ..." 
                                                                    className={inputClassName(formik?.arrForm?.errors?.grupoid)}
                                                                    />
                                                                </>
                                                        }
                                                            </DataSource>
                                                   
                                                </div>
                                                <FieldArray name="arrForm" render={
                                                    arrayHelpers => (
                                                        <div className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                                            <table cellPadding="3" className="p-datatable-table w-full">
                                                                <thead className="p-table-head text-left">
                                                                    <tr>
                                                                        <th>Produto</th>
                                                                        <td style={{ width: '30px' }}></td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        formik.values.arrForm.map((rowForm, index) =>
                                                                            <tr key={index} className="input-row">
                                                                                <td>
                                                                                    <DataSource apiPath="components_data/proid_option_list"  >
                                                                                        {
                                                                                            ({ response }) =>
                                                                                                <>
                                                                                                    <Dropdown 
                                                                                                    name={`arrForm.${index}.proid`} 
                                                                                                    optionLabel="label" 
                                                                                                    optionValue="value" 
                                                                                                    value={rowForm.proid} 
                                                                                                    onChange={
                                                                                                        (e)=>{
                                                                                                            formik.handleChange(e)
                                                                                                            formik.values.arrForm[index].grupoid = formik.values.arrForm[0].grupoid
                                                                                                    }
                                                                                                    } 
                                                                                                    options={response} 
                                                                                                    label="Produto" 
                                                                                                    placeholder="Selecione um valor ..." 
                                                                                                    className={inputClassName('')} />
                                                                                                    <ErrorMessage name={`arrForm.${index}.proid`} 
                                                                                                    component="span" 
                                                                                                    className="p-error" 
                                                                                                    
                                                                                                    />
                                                                                                </>
                                                                                        }
                                                                                    </DataSource>
                                                                                </td>
                                                                                <th style={{ width: '80px' }}>
                                                                                    {
                                                                                        formik.values.arrForm.length > props.minRowCount &&
                                                                                        <>
                                                                                            <Button type="button" 
                                                                                            className="p-button-danger p-button-sm" 
                                                                                            onClick={() => arrayHelpers.remove(index)} 
                                                                                            icon="pi pi-times" />
                                                                                            <Button type="button" 
                                                                                            onClick={() => arrayHelpers.insert(index, { ...formData.arrForm[0] })} 
                                                                                            icon="pi pi-plus" 
                                                                                            className="p-button-success p-button-sm" />
                                                                                        </>
                                                                                    }
                                                                                </th>
                                                                            </tr>)
                                                                    }
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colSpan="100" className="text-right">
                                                                            {
                                                                                (formik.values.arrForm.length < props.maxRowCount) &&
                                                                                <Button type="button" 
                                                                                onClick={() => arrayHelpers.push({ ...formData.arrForm[0] })} 
                                                                                icon="pi pi-plus" 
                                                                                className="p-button-success p-button-sm" />
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    )}
                                                />
                                                {props.showFooter &&
                                                    <div className="text-center q-my-md">
                                                        <Button onClick={(e) => {
                                                            handleSubmit(e, formik)}} 
                                                        type="submit" 
                                                        label="Enviar" 
                                                        icon="pi pi-send" 
                                                        loading={saving} />
                                                    </div>
                                                }
                                            </Form>
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

//page props and default values
ProdutogruposAddPage.defaultProps = {
    primaryKey: 'id',
    grupoid: '',
    pageName: 'produtogrupos',
    apiPath: 'produtogrupos/addmulti',
    routeName: 'produtogruposadd',
    submitButtonLabel: "Enviar",
    formValidationError: "O formulário é inválido",
    formValidationMsg: "Por favor, preencha o formulário",
    msgTitle: "Criar registro",
    msgAfterSave: "Registro adicionado com sucesso",
    msgBeforeSave: "Deseja Salvar os Dados Agora?",
    showHeader: true,
    showFooter: true,
    redirect: true,
    isSubPage: false,
    maxRowCount: 10,
    minRowCount: 1,
}
export default ProdutogruposAddPage;
