import { useFormikContext } from 'formik';
import { Chip } from 'primereact/chip';
import * as React from 'react';

export const Utils = () => {

  function ChooseItem(valor, parametrosRetorno) {
    for (const parametro of parametrosRetorno) {
      if (parametro.valor === valor) {
        return parametro.titulo; // Retorna o título correspondente
      }
    }
    return 'Desconhecido'; // Retorna 'Desconhecido' se o valor não for encontrado
  }

  function getFormatDate(date) {
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    return formattedDate;
  }

  function FirstDateOfCurrentMonth() {
    const now = new Date();
    const _date = new Date(now.getFullYear(), now.getMonth(), 1);
    const formattedDate = getFormatDate(_date);
    return formattedDate;
  }
  
  function LastDateOfCurrentMonth() {
    const now = new Date();
    const _date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const formattedDate = getFormatDate(_date);
    return formattedDate;
  }

  // Return all Functions
  return { ChooseItem, FirstDateOfCurrentMonth, LastDateOfCurrentMonth   };

}




export default Utils;