import { confirmDialog } from 'primereact/confirmdialog';

// helper.Dialog.js
export class HelperDialog {

    // Confirmação
    async Confirm(title, info) {
        title = title || 'Confirmar';
        info = info || 'Confirmar a operação?';
        return new Promise((resolve) => {
            confirmDialog({
                message: `${info}. Confirma?`,
                header: `${title}`,
                icon: 'pi pi-question',
                defaultFocus: 'Cancelar',
                acceptClassName: 'p-button-danger',
                accept: () => resolve(true),
                reject: () => resolve(false),
            });
        });
    }

    // Erro
    async Error(info) {
        let title = "Erro";
        info = info || 'Ocorreu um erro.';
        return new Promise((resolve) => {
            confirmDialog({
                message: `${info}`,
                header: `${title}`,
                icon: 'pi pi-exclamation-triangle', // Ícone mais adequado para erro
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Ok',
                rejectHide: true, // Esconde o botão "Não"
                accept: () => resolve(true)
            });
        });
    }

    // Informação
    async Info(info) {
        let title = "Informação";
        info = info || 'Operação realizada com sucesso!';
        return new Promise((resolve) => {
            confirmDialog({
                message: `${info}`,
                header: `${title}`,
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-primary', // Classe para botão de informação
                acceptLabel: 'Ok',
                rejectHide: true, // Esconde o botão "Não"
                accept: () => resolve(true),
                reject: () => resolve(false)
            });
        });
    }
}

// helper.Dialog.js (continuação)
const dialog = new HelperDialog(); // Cria uma instância da classe
export default { dialog: dialog }; // Exporta a instância
