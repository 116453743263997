import React, { } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import GridMethods from "./Grid.Methods";

export default class GridComponents {

    ActionButton(rowData, options) {
        return (
            <div className="p-buttonset">
                <Button
                    visible={rowData.isEditing == false}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-info p-button-text"
                    onClick={() => options.startEditing(rowData)}
                    tooltip="Editar"
                    tooltipOptions={{ position: "top" }}
                />
                <Button
                    visible={rowData.isEditing == true}
                    icon="pi pi-check"
                    className="p-button-rounded p-button-danger p-button-text"
                    onClick={() => {
                        options.onRowEditComplete({ newData: rowData, index: options.rowIndex });
                        options.stopEditing(rowData);
                    }}
                    tooltip="Salvar"
                    tooltipOptions={{ position: "top" }}
                />
                <Button
                    visible={rowData.isEditing == false}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button-text"
                    onClick={() => {
                        options.stopEditing(rowData);
                    }}
                    tooltip="Excluir"
                    tooltipOptions={{ position: "top" }}
                />
            </div>
        );
    }

    // Api List Data from Combo
    edProduto = (row, ProdutosList) => {
        if (row.rowData.prodid == null) {
            return (
                <Dropdown
                    filter
                    value={row.value}
                    options={ProdutosList}
                    optionLabel="nome"
                    optionValue="id"
                    onChange={(rowData) => row.editorCallback(rowData.target.value)}
                    placeholder="Selecione um produto"
                />
            );
        } else {
            return row.rowData.prodNome;
        }


    };

    // Qtde Integer
    edPeriodic = (row) => {
        return (
            <InputText
                value={row.value}
                onChange={(rowData) => row.editorCallback(rowData.target.value)}
            />
        );
    };

    edComboQtde = (options) => {
        const qtdList = Array.from({ length: 10 }, (v, k) => k + 1);
        return (
            <Dropdown
                value={options.rowData.qtde}
                options={qtdList}
                onChange={(rowData) => options.editorCallback(rowData.value)}
                placeholder="Selecione"
            />
        );
    };

}
