import { useEffect, useContext } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CanView } from "components/Can";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ExportPageData } from "components/ExportPageData";
import { FilterTags } from "components/FilterTags";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { PageRequestError } from "components/PageRequestError";
import { Paginator } from "primereact/paginator";
import { ProgressSpinner } from "primereact/progressspinner";
import { SplitButton } from "primereact/splitbutton";
import { Title } from "components/Title";
import EmpresasAddPage from "pages/empresas/Add";
import EmpresasEditPage from "pages/empresas/Edit";
import { TokenInfo } from "./TokenInfo";
import useApp from "hooks/useApp";
import useAuth from "hooks/useAuth";
import UsersViewPage from "pages/users/View";
import useUtils from "hooks/useUtils";
import useListPage from "hooks/useListPage";
import ResetPassword from 'pages/index/ResetPassword';
import { Chip } from 'primereact/chip';
import StatusBarIcons from "components/StatusBardIcons";
import { SwitchFrag } from "components/Fragments/ComponentsFrag";

const EmpresasListPage = (props) => {
    const auth = useAuth();
    const app = useApp();
    const utils = useUtils();
    const { empid, setempid } = useApp();

    const filterSchema = {
        search: {
            tagTitle: "Search",
            value: "",
            valueType: "single",
            options: [],
        },
    };

    const statusIcons = [
        { icon: 'pi pi-lock', color:'yellow', hint: 'Bloqueado' }, // Font Awesome example
        { icon: 'pi pi-lock-open', color:'white', hint: 'Liberado' },
    ];
    

    const pageController = useListPage(props, filterSchema);
    const filterController = pageController.filterController;
    const {
        records,
        pageReady,
        loading,
        selectedItems,
        apiUrl,
        sortBy,
        sortOrder,
        apiRequestError,
        getPageBreadCrumbs,
        onSort,
        deleteItem,
        pagination,
    } = pageController;
    const { filters, setFilterValue } = filterController;
    const {
        totalRecords,
        totalPages,
        recordsPosition,
        firstRow,
        limit,
        onPageChange,
    } = pagination;

    function ActionButton(data) {
        const titleemp = "Empresa : " + data.razaosocial;
        const items = [
            {
                label: "Editar",
                command: () => {
                    app.openPageDialog(
                        <EmpresasEditPage
                            isSubPage
                            apiPath={`/empresas/edit/${data.id}`}
                        />,
                        { closeBtn: true }
                    );
                },
                icon: "pi pi-pencil",
                visible: () => auth.canView("empresas/edit"),
            },
            // {
            //     label: "Excluir",
            //     command: () => {
            //         deleteItem(data.id);
            //     },
            //     icon: "pi pi-trash",
            //     visible: () => auth.canView("empresas/delete") && data.id > 1,
            // },
            {
                label: "Resetar Senha",
                command: () => {
                    app.openPageDialog(<ResetPassword title={titleemp} id={data.userid} />, { width: "30vw", closeBtn: true })
                },
                icon: "pi pi-refresh",
                visible: () => data.userid !== null
            }
        ].filter((item) => {
            if (item.visible) {
                return item.visible();
            }
            return true;
        });

        return (
            <SplitButton
                dropdownIcon="pi pi-bars"
                className="dropdown-only p-button-text p-button-plain"
                model={items}
            />
        );
    }

    const OnClickLockUnLock = (param) => {
        const data = {
            id: param.id,
            active: !param.active
        }

    }

    function handleSwitchChange(id) {
        console.clear();
        const checked = !records.find(record => record.id === id).active;
        const updatedRecords = records.map(record => {
            if (record.id === id) {
                return { ...record, active: true };
            }
            return record;
        });
        //first record 
        const firstRecord = updatedRecords[0];
        console.log(updatedRecords[0].razaosocial + " - " + updatedRecords[0].active);
        // Update the state with the new records
        //pageController.setRecords(updatedRecords[0].active);
    }

    function TipoTemplate(data) {
        if (data.tipo === "M") {
            return <Chip label="SISTEMA" className="font-bold" style={{ backgroundColor: 'blue', color: 'white' }} />;
        } else {
            return <Chip label="CLIENTE" style={{ backgroundColor: 'green', color: 'white' }} />;
        }
    }

    function TemplatToken(data) {
        if (data) {
            if ((data.active == true) && (data.tipo == 'F')) {
                return (
                    <Button
                        label="Token"
                        icon="pi pi-key"
                        onClick={() =>
                            app.openPageDialog(<TokenInfo data={data} />, {
                                width: "50vw",
                                closeBtn: true,
                            })
                        }
                    />
                );
            }
        }
    }

    function ActiveTemplate(data) {
        if (data) {
            return <>{app.FmtStatusChip({ value: data.active })}</>;
        }
    }

    function EmptyRecordMessage() {
        if (pageReady && !records.length) {
            return (
                <div className="text-lg mt-3 p-3 text-center text-400 font-bold">
                    Nenhum Registro Encontrado
                </div>
            );
        }
    }
    function MultiDelete() {
        if (selectedItems.length) {
            return (
                <div className="m-2 flex-grow-0">
                    <Button
                        onClick={() => deleteItem(selectedItems)}
                        icon="pi pi-trash"
                        className="p-button-danger"
                        title="Excluir selecionado"
                    />
                </div>
            );
        }
    }
    function ExportData() {
        if (props.exportData && records.length) {
            const downloadFileName = `${utils.dateNow()}-empresas`;
            return (
                <div className="m-2">
                    <ExportPageData
                        csv={false}
                        pageUrl={apiUrl}
                        downloadFileName={downloadFileName}
                        butonLabel="Exportar"
                        tooltip="Exportar"
                        buttonIcon="pi pi-print"
                    />
                </div>
            );
        }
    }
    function PagerControl() {
        if (props.paginate && totalPages > 1) {
            const pagerReportTemplate = {
                layout: pagination.layout,
                CurrentPageReport: (options) => {
                    return (
                        <>
                            <span className="text-sm text-gray-500 px-2">
                                Registros{" "}
                                <b>
                                    {recordsPosition} do {options.totalRecords}
                                </b>
                            </span>
                        </>
                    );
                },
            };
            return (
                <div className="flex-grow-1">
                    <Paginator
                        first={firstRow}
                        rows={limit}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        template={pagerReportTemplate}
                    />
                </div>
            );
        }
    }
    function PageActionButtons() {
        return (
            <div className="flex flex-wrap">
                <CanView pagePath="empresas/delete">
                    <MultiDelete />
                </CanView>
                <ExportData />
            </div>
        );
    }
    function PageFooter() {
        if (pageReady && props.showFooter) {
            return (
                <div className="flex flex-wrap">
                    <PageActionButtons />
                    <PagerControl />
                </div>
            );
        }
    }
    function PageBreadcrumbs() {
        if (props.showBreadcrumbs) {
            const items = getPageBreadCrumbs();
            return (
                items.length > 0 && (
                    <BreadCrumb className="mb-3" model={items} />
                )
            );
        }
    }
    if (apiRequestError) {
        return <PageRequestError error={apiRequestError} />;
    }
    return (
        <main id="EmpresasListPage" className="main-page">

            {props.showHeader && (
                <section className="page-section mb-3">
                    <div className="container-fluid">
                        <div className="grid justify-content-between align-items-center">
                            <div className="col ">
                                <Title
                                    title="Empresas"
                                    titleClass="text-2xl text-primary font-bold"
                                    subTitleClass="text-500"
                                    separator={false}
                                />
                            </div>
                            <div className="col-fixed ">
                                <CanView pagePath="empresas/add">
                                    <Button
                                        label="Adicionar novo"
                                        icon="pi pi-plus"
                                        onClick={() =>
                                            app.openPageDialog(
                                                <EmpresasAddPage
                                                    isSubPage
                                                    apiPath={`/empresas/add`}
                                                />,
                                                { closeBtn: true }
                                            )
                                        }
                                        className="p-button w-full bg-primary "
                                    />
                                </CanView>
                            </div>
                            <div className="col-12 md:col-3 ">
                                <span className="p-input-icon-left w-full">
                                    <i className="pi pi-search" />
                                    <InputText
                                        placeholder="Pesquisa"
                                        className="w-full"
                                        value={filters.search.value}
                                        onChange={(e) =>
                                            setFilterValue(
                                                "search",
                                                e.target.value
                                            )
                                        }
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            <section className="page-section ">
                <div className="container-fluid">
                    <div className="grid ">
                        <div className="col comp-grid">
                            <FilterTags filterController={filterController} />
                            <StatusBarIcons title={"LEGENDA"} icons={statusIcons} />
                            <div>
    
                                <PageBreadcrumbs />
                                <div className="page-records">
                                    <DataTable
                                        lazy={true}
                                        loading={loading}
                                        value={records}
                                        dataKey="id"
                                        sortField={sortBy}
                                        sortOrder={sortOrder}
                                        onSort={onSort}
                                        className=" p-datatable-sm"
                                        stripedRows={true}
                                        showGridlines={false}
                                        rowHover={true}
                                        responsiveLayout="stack"
                                        emptyMessage={<EmptyRecordMessage />}
                                    >
                                        {/*PageComponentStart*/}
                                        <Column
                                            headerStyle={{ width: "5%" }}
                                            field="id"
                                            header="Id"
                                        ></Column>
                                        <Column
                                            headerStyle={{ width: "5%" }}
                                            field="tipo"
                                            header="Tipo"
                                            body={TipoTemplate}
                                        ></Column>

                                        <Column
                                            headerStyle={{ width: "100%" }}
                                            field="razaosocial"
                                            header="Razaosocial"
                                        ></Column>
                                        <Column
                                            headerStyle={{ width: "2rem" }}
                                            headerClass="text-center"
                                            body={TemplatToken}
                                        ></Column>
                                        <Column
                                            headerStyle={{ width: "2rem" }}
                                            headerClass="text-center"
                                            body={ActionButton}
                                        ></Column>                    
                                        <Column
                                            field="active"
                                            header="Ativo?"
                                            headerStyle={{ width: "2rem" }}
                                            headerClass="text-center"
                                            body={(rowData) => (
                                                <SwitchFrag
                                                    checked={rowData.active}
                                                    onChange={() => handleSwitchChange(rowData.id)}
                                                />
                                            )}
                                        ></Column>
                                        {/*PageComponentEnd*/}
                                    </DataTable>
                                </div>
                                <PageFooter />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
EmpresasListPage.defaultProps = {
    primaryKey: "id",
    pageName: "empresas",
    apiPath: "empresas/index",
    routeName: "empresaslist",
    msgBeforeDelete: "Tem certeza de que deseja excluir este registro?",
    msgTitle: "Apagar registro",
    msgAfterDelete: "Registro excluído com sucesso",
    showHeader: true,
    showFooter: true,
    paginate: true,
    isSubPage: false,
    showBreadcrumbs: true,
    exportData: true,
    importData: false,
    keepRecords: false,
    multiCheckbox: true,
    search: "",
    fieldName: null,
    fieldValue: null,
    sortField: "",
    sortDir: "",
    pageNo: 1,
    limit: 100,
};
export default EmpresasListPage;
