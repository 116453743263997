import { InputSwitch } from 'primereact/inputswitch';

function LockedFrag(status) {
    if (status) {
        return (
            <div style={{ textAlign: 'center' }}>
                {status === true ? (
                    <i className="pi pi-lock-open" style={{ color: 'white', fontSize: '1.5em' }}></i>
                ) : (
                    <i className="pi pi-lock" style={{ color: 'yellow', fontSize: '1.5em' }}></i>
                )}
            </div>
        );
    } else {
        return (
            <div style={{ textAlign: 'center' }}>
                <i className="pi pi-lock" style={{ color: 'yellow', fontSize: '1.5em' }}></i>
            </div>
        );
    }
}

function InputOutputFrag(status) {
    if (status) {
        return (
            <div style={{ textAlign: 'center' }}>
                {status === true ? (
                    <i className="pi pi-lock-open" style={{ color: 'white', fontSize: '1.5em' }}></i>
                ) : (
                    <i className="pi pi-lock" style={{ color: 'yellow', fontSize: '1.5em' }}></i>
                )}
            </div>
        );
    } else {
        return (
            <div style={{ textAlign: 'center' }}>
                <i className="pi pi-lock" style={{ color: 'yellow', fontSize: '1.5em' }}></i>
            </div>
        );
    }
}

const SwitchFrag = ({ checked, onChange }) => {
  return (
    console.log("SwitchFrag called with name:", name, "checked:", checked),
    <label className="relative inline-flex items-center cursor-pointer">
      <InputSwitch  
        // name={name} 
        checked={checked} 
        onChange={(e) => onChange(e.target.checked)} 
      />      
      <div style={{ marginLeft: '10px' }}>
          <LockedFrag status={checked} />
      </div>
    </label>
  );
};

export default SwitchFrag;


export { LockedFrag, SwitchFrag };

