import React, { } from "react";
import useApi from "hooks/useApi";
import useApp from "hooks/useApp";
import ListGroupsToSelect from "./ListGroupsToSelect";
import GruposGestorEditPage from "./Edit";
import GruposGestorAddPage from "./Add";

export default class GridMethods extends React.Component {


    constructor(props) {
        super(props);
        this.api = useApi();
        this.app = useApp();
        this.state = {
            GroupList: [],
        };
    }

    /// Método para carregar a lista de grupos
    fetchDataGrupsList = async (onSuccess, onError) => {
        try {
            const response = await this.api.get("GrupoGestor/list");
            if (onSuccess) onSuccess(response.data); // Chama o callback de sucesso
            return response.data;
        } catch (error) {
            if (onError) onError(error); // Chama o callback de erro            
            return [];
        }
    };
    /// Método para carregar a lista de produtos
    fetchProdutosAsync = async (onSuccess, onError) => {
        try {
            const response = await this.api.get(`produtos?search=`);
            if (onSuccess) onSuccess(response.data); // Chama o callback de sucesso
            return response.data.records;
        } catch (error) {
            console.error("Erro ao buscar produtos:", error);
            if (onError) onError(error); // Chama o callback de erro
            return [];
        }
    };

    /// Método para carregar a lista de produtos do grupo
    fetchGestorProductList = async (param, onSuccess, onError) => {
        try {
            const response = await this.api.get(`grupogestor/list/grid/${param}`);
            if (onSuccess) onSuccess(response.data); // Chama o callback de sucesso
            return response.data;
        } catch (error) {
            console.error("Erro ao buscar produtos:", error);
            if (onError) onError(error); // Chama o callback de erro
            return [];
        }
    };

    /// Método para salvar os produtos do grupo na Base
    OnRowEditSave = async (rowData) => {
        let postData = {
            grupoid: rowData.grupoid,
            prodid: rowData.prodid,
            qtde: rowData.qtde,
            periodic: rowData.periodic,
        };
        console.log("Salvando produto do grupo:", postData);
        try {
            let response = await this.api.post("grupogestor/add/produto", postData);
        } catch (rowData) {
            //console.error("Erro ao salvar:", rowData);
        }
    };

    /// Adiciona uma nova linha
    OnClickAddNewRow = async (SelectedGroup, rowData, OnNewRowData) => {
        try {
            console.log(rowData);
            let maxId = Math.max(...rowData.map(item => item.id), 0);
            maxId++;
            console.log("MaxId:", maxId);

            const newProd = {
                id: maxId,
                grupoid: SelectedGroup,
                prodid: null,
                prodNome: "CLICK AO LADO PARA EDITAR ------------------------>",
                qtde: 1,
                periodic: 90,
            };
            const newRowData = rowData.concat(newProd);
            OnNewRowData(newRowData);
            return { success: true };
        } catch (error) {
            console.log("Erro ao adicionar nova linha:", error);
            return { success: false };
        }
    };

    /// Seleciona um grupo
    OnClickSelectProductGroup = (SelectedGroup) => {
        this.app.openPageDialog(<ListGroupsToSelect grupoid={SelectedGroup} />, {
            maximized: false,
            closeBtn: true,
            width: "50vw",
        }).
        this.app.flashMsg("Grade Atualizada!");
    };

    OnClickEditGroup = async (SelectedGroup) => {
        // this.app.openPageDialog(
        //     <GruposGestorEditPage
        //         apiPath={`/grupogestor/edit/${SelectedGroup}`}
        //     />,
        //     { maximized: true, closeBtn: true, width: "50vw" }
        // );
        //await fetchGestorProductList(SelectecGroup);
    };

    OnClickAddGroup = () => {
        this.app.openPageDialog(<GruposGestorAddPage />, {
            maximized: true,
            closeBtn: true,
            width: "50vw",
        });
    };

    RereshData = async () => {
        this.app.refreshPage();
    }

    OnClickExcludeSelection = async (row) => {
        const confirmed = await this.app.dialog.Confirm("Confirmação", "Tem certeza que deseja excluir o itens selecionado?");
        if (confirmed == true) {
            try {
                let rows = row.map((item) => item.id);
                let selectedGrupo = row[0].grupoid;
                let query = rows.map((item) => `prods=${item}`).join("&");
                await this.api.delete(`GrupoGestor?groupid=${selectedGrupo}&${query}`);
                this.app.flashMsg("Itens Excluidos com Sucesso excluído com sucesso!");                
                return { success : true };
            } catch (error) {
                console.error("Erro ao excluir:", error);
                return { success : false };
            }
        }
    }

}
