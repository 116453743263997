import { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

import useApp from 'hooks/useApp';
import useApi from 'hooks/useApi';
import useLocalStore from 'hooks/useLocalStore';

export const TokenInfo = (data) => {
	const localStore = useLocalStore();
	const app = useApp();
	const api = useApi();
	const { empid, setempid } = useApp();
	const [token, setToken] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [empresaInfo, setEmpresaInfo] = useState(null);
	const [texto, setTexto] = useState('Seu token será gerado para acesso à empresa.');
	const toast = useRef(null);

	const empresaInfoRequest = () => {
		if (!empresaInfo)
			setEmpresaInfo(data.data)
	}

	const TokenGenerate = async () => {
		setIsLoading(true);
		setTexto("Gerando token...");

		let formPost = {
			id: 0,
			empId: empresaInfo.id
		};

		let json = JSON.stringify(formPost);

		try {
			let response = await api.post("/Auth/TokenGenerator", json);
			setToken(response.data);
			setTexto("Token gerado com sucesso!");
			toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Token gerado com sucesso!', life: 3000 });
		} catch (error) {
			setTexto("Falha ao gerar o token.");
			toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Falha ao gerar o token.', life: 3000 });
		} finally {
			setIsLoading(false);
		}
	}

	const copy = (tk) => {
		navigator.clipboard.writeText(tk).then(() => {
			toast.current.show({ severity: 'info', summary: 'Copiado', detail: 'Token copiado para a área de transferência!', life: 3000 });
		}).catch(err => {
			console.error("Falha ao copiar o texto: ", err);
			toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Falha ao copiar o token.', life: 3000 });
		});
	}

	return (
		<div className="p-4">
			<Toast ref={toast} />
			<h2 className="text-2xl font-bold mb-4">Novo Token</h2>
			<div className="mb-4">
				<p>{texto}</p>
				{empresaInfoRequest()}
				{empresaInfo &&
					<div className="mt-4">
						<div className="p-4 bg-gray-100 rounded-lg">
							<div className="text-sm mb-2">Empresa:</div>
							<div className="font-bold">{empresaInfo.razaosocial}</div>
						</div>
					</div>
				}
			</div>

			<div className="card p-4 text-center">
				<InputTextarea
					value={token}
					className="w-full mb-4"
					rows={5}
					readOnly
				/>
				<div className="flex justify-end">
					<Button
						className="p-button-outlined"
						disabled={!token}
						onClick={() => copy(token)}
						icon="pi pi-copy"
						label="Copiar Token"
					/>
				</div>
			</div>

			<div className="mt-4 text-center">
				{isLoading ? (
					<ProgressSpinner />
				) : (
					<Button
						className="p-button-success"
						onClick={TokenGenerate}
						icon="pi pi-key"
						label="Gerar Token"
						disabled={isLoading}
					/>
				)}
			</div>
		</div>
	);
}