import { Formik, Form, ErrorMessage, Field } from "formik";
import * as yup from "yup";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { DataSource } from "components/DataSource";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { PageRequestError } from "components/PageRequestError";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import { Title } from "components/Title";
import { Uploader } from "components/Uploader";
import useApp from "hooks/useApp";
import useApi from "hooks/useApi";
import MultiSelectGroup from "../custom/MultiSelectGroup";
import { React, useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import ProductForm from "./Product.Form";
import { formDefaultValues, validationSchema } from "./Product.Validation";
import useEditPage from "hooks/useEditPage";
const ProdutosEditPage = (props) => {
    const app = useApp();

    //where page logics resides
    const pageController = useEditPage({
        props,
        formDefaultValues,
        afterSubmit,
    });
    //destructure and grab what we need
    const {
        formData,
        handleSubmit,
        submitForm,
        pageReady,
        loading,
        saving,
        apiRequestError,
        inputClassName,
    } = pageController;

    const [CheckActive, setCheckActive] = useState(false);
    const [CheckIntegracao, setCheckIntegracao] = useState(false);

    const ListQtde = new Array(10)
        .fill(0)
        .map((_, index) => ({ name: index + 1, value: index + 1 }));
    const [SelectedQtde, setSelectedQtde] = useState(1);
    const [SelectedPeriodo, setSelectedPeriodo] = useState(30);

    //Event raised on form submit success
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        if (app.isDialogOpen()) {
            app.closeDialogs(); // if page is open as dialog, close dialog
        } else if (props.redirect) {
            app.navigate(`/produtos`);
        }
    }
    // loading form data from api
    if (loading) {
        return (
            <div className="p-3 text-center">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
            </div>
        );
    }
    //display error page
    if (apiRequestError) {
        return <PageRequestError error={apiRequestError} />;
    }
    //page is ready when formdata loaded successfully
    if (pageReady) {
        return (
            <main id="ProdutosEditPage" className="main-page">
                {props.showHeader && (
                    <section className="page-section mb-3">
                        <div className="container">
                            <div className="grid justify-content-between align-items-center">
                                {!props.isSubPage && (
                                    <div className="col-fixed ">
                                        <Button
                                            onClick={() => app.navigate(-1)}
                                            label=""
                                            className="p-button p-button-text "
                                            icon="pi pi-arrow-left"
                                        />
                                    </div>
                                )}
                                <div className="col ">
                                    <Title
                                        title="Editar Produto"
                                        titleClass="text-2xl text-primary font-bold"
                                        subTitleClass="text-500"
                                        separator={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                <section className="page-section ">
                    <div className="container">
                        <div className="grid ">
                            <div className="md:col-9 sm:col-12 comp-grid">
                                <div>
                                    <Formik
                                        initialValues={formData}
                                        validationSchema={validationSchema}
                                        onSubmit={(values, actions) => {
                                            submitForm(values);
                                        }}
                                    >
                                        {(formik) => {
                                            useEffect(() => {
                                                setCheckActive(
                                                    formik.values.active
                                                );
                                                setCheckIntegracao(
                                                    formik.values.integracao
                                                );
                                            }, []);

                                            return (
                                                <Form
                                                    className={`${
                                                        !props.isSubPage
                                                            ? "card  "
                                                            : ""
                                                    }`}
                                                >
                                                    <ProductForm
                                                        formik={formik}
                                                        formData={
                                                            formDefaultValues
                                                        }
                                                        inputClassName={
                                                            inputClassName
                                                        }
                                                        app={app}
                                                        setCheckActive={
                                                            setCheckActive
                                                        }
                                                        CheckActive={
                                                            CheckActive
                                                        }

                                                        setCheckIntegracao={
                                                            setCheckIntegracao
                                                        }
                                                        CheckIntegracao={
                                                            CheckIntegracao
                                                        }
                                                    />

                                                    {props.showFooter && (
                                                        <div className="text-center my-3">
                                                            <Button
                                                                onClick={(e) =>
                                                            {
                                                                    handleSubmit(
                                                                        e,
                                                                        formik
                                                                    )
                                                                }
                                                            }
                                                                type="submit"
                                                                label="Atualizar"
                                                                icon="pi pi-send"
                                                                loading={saving}
                                                            />
                                                        </div>
                                                    )}
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
};
ProdutosEditPage.defaultProps = {
    primaryKey: "id",
    pageName: "produtos",
    apiPath: "produtos/edit",
    routeName: "produtosedit",
    submitButtonLabel: "Atualizar",
    formValidationError: "O formulário é inválido",
    formValidationMsg: "Por favor, preencha o formulário",
    msgTitle: "Atualizar registro",
    msgAfterSave: "Registro atualizado com sucesso",
    msgBeforeSave: "Deseja Salvar os Dados Agora?",
    showHeader: true,
    showFooter: true,
    redirect: true,
    isSubPage: false,
};
export default ProdutosEditPage;
