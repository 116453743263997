import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { ErrorMessage } from 'formik';
import { Uploader } from 'components/Uploader';
import MultiSelectGroup from "../custom/MultiSelectGroup";

const ProductForm = ({ formik, formData, inputClassName, app, setCheckActive, CheckActive, setCheckIntegracao, CheckIntegracao }) => {
    return (
        <div className="grid">
            <div className="md:col-8 col-12">
                Nome *
                <div>
                    <InputText
                        // disabled={app.auth.user.prodintegra}
                        name="nome"
                        onChange={formik.handleChange}
                        value={formik.values.nome}
                        label="Nome"
                        type="text"
                        placeholder="Entrar Nome"
                        className={inputClassName(formik?.errors?.nome)}
                    />
                    <ErrorMessage name="nome" component="span" className="p-error" />
                </div>
            </div>
            <div className="md:col-4 col-12">
                Nome Reduzido *
                <div>
                    <InputText
                        // disabled={app.auth.user.prodintegra}
                        name="nomereduzido"
                        onChange={formik.handleChange}
                        value={formik.values.nomereduzido}
                        label="Nome Reduzido"
                        type="text"
                        placeholder="Entrar Nome Reduzido"
                        className={inputClassName(formik?.errors?.nomereduzido)}
                    />
                    <ErrorMessage name="nomereduzido" component="span" className="p-error" />
                </div>
            </div>
            <div className="md:col-12 col-12">
                Agrupamento *
                <MultiSelectGroup
                    name="Grupo"
                    multiSelect={false}
                    CurrentData={formik.values.grupoid}
                    onChange={(e) => (formik.values.grupoid = e.value)}
                    endpoint=""
                    fields={{ label: "label", value: "value" }}
                />
            </div>
            <div className="md:col-4 col-12">
                EAN (BARRAS) *
                <div>
                    <InputText
                        // disabled={app.auth.user.prodintegra}
                        name="ean"
                        onChange={formik.handleChange}
                        value={formik.values.ean}
                        label="EAN (BARRAS)"
                        type="text"
                        placeholder="Entrar EAN (BARRAS)"
                        className={inputClassName(formik?.errors?.ean)}
                    />
                    <ErrorMessage name="ean" component="span" className="p-error" />
                </div>
            </div>
            <div className="md:col-3 col-12">
                Cod Externo *
                <div>
                    <InputText
                        name="codexterno"
                        onChange={formik.handleChange}
                        value={formik.values.codexterno}
                        label="Cod Externo"
                        type="text"
                        placeholder="Entrar Cod Externo"
                        className={inputClassName(formik?.errors?.codexterno)}
                    />
                    <ErrorMessage name="codexterno" component="span" className="p-error" />
                </div>
            </div>
            <div className="md:col-3 col-12">
                Cod. Fabr *
                <div>
                    <InputText
                        // disabled={app.auth.user.prodintegra}
                        name="codfabrica"
                        onChange={formik.handleChange}
                        value={formik.values.codfabrica}
                        label="Cod. Fabr"
                        type="text"
                        placeholder="Entrar Cod. Fabr"
                        className={inputClassName(formik?.errors?.codfabrica)}
                    />
                    <ErrorMessage name="codfabrica" component="span" className="p-error" />
                </div>
            </div>
            <div className="md:col-2 col-12">
                UND *
                <div>
                    <InputText
                        // disabled={app.auth.user.prodintegra}
                        name="unidade"
                        onChange={formik.handleChange}
                        value={formik.values.unidade}
                        label="UND"
                        type="text"
                        placeholder="Entrar UND"
                        className={inputClassName(formik?.errors?.unidade)}
                    />
                    <ErrorMessage name="unidade" component="span" className="p-error" />
                </div>
            </div>
            <div className="md:col-8 col-12">
                Certificado de Aprovação (CA) *
                <div>
                    <InputText
                        // disabled={app.auth.user.prodintegra}
                        name="ca"
                        onChange={formik.handleChange}
                        value={formik.values.ca}
                        label="Certificado de Aprovação (CA)"
                        type="text"
                        placeholder="Entrar Certificado de Aprovação (CA)"
                        className={inputClassName(formik?.errors?.ca)}
                    />
                    <ErrorMessage name="ca" component="span" className="p-error" />
                </div>
            </div>
            <div className="md:col-4 col-12">
                Validade *
                <div>
                    <Calendar
                        // disabled={app.auth.user.prodintegra}
                        name="validade"
                        showButtonBar
                        className={inputClassName(formik?.errors?.validade)}
                        dateFormat="yy-mm-dd"
                        value={formik.values.validade}
                        onChange={formik.handleChange}
                        showIcon
                    />
                    <ErrorMessage name="validade" component="span" className="p-error" />
                </div>
            </div>
            <div className="md:col-4 col-12">
                Custo *
                <div>
                    <InputText
                        // disabled={app.auth.user.prodintegra}
                        name="custo"
                        onChange={formik.handleChange}
                        value={formik.values.custo}
                        label="Custo"
                        type="number"
                        placeholder="Entrar Custo"
                        min={0}
                        step={1}
                        className={inputClassName(formik?.errors?.custo)}
                    />
                    <ErrorMessage name="custo" component="span" className="p-error" />
                </div>
            </div>
            <div className="md:col-4 col-12">
                Venda *
                <div>
                    <InputText
                        // disabled={app.auth.user.prodintegra}
                        name="venda"
                        onChange={formik.handleChange}
                        value={formik.values.venda}
                        label="Venda"
                        type="number"
                        placeholder="Entrar Venda"
                        min={0}
                        step={1}
                        className={inputClassName(formik?.errors?.venda)}
                    />
                    <ErrorMessage name="venda" component="span" className="p-error" />
                </div>
            </div>
            <div className="md:col-2 col-12">
                Estoque *
                <div>
                    <InputText
                        name="estoque"
                        onChange={formik.handleChange}
                        value={formik.values.estoque}
                        label="Estoque"
                        type="number"
                        placeholder="Entrar Estoque"
                        min={0}
                        step={1}
                        readonly
                        className={inputClassName(formik?.errors?.estoque)}
                    />
                    <ErrorMessage name="estoque" component="span" className="p-error" />
                </div>
            </div>
            <div className="md:col-2 col-12">
                Estoque Cliente*
                <div>
                    <InputText
                        name="estoquelocal"
                        onChange={formik.handleChange}
                        value={formik.values.estoquelocal}
                        label="Estoque"
                        type="number"
                        placeholder="Entrar Estoque"
                        min={0}
                        step={1}
                        readonly
                        className={inputClassName(formik?.errors?.estoquelocal)}
                    />
                    <ErrorMessage name="estoquelocal" component="span" className="p-error" />
                </div>
            </div>
            <div className="md:col-6 col-6">
                <div className="flex align-items-center">
                    <Checkbox
                        name="active"
                        checked={formik.values.active}
                        onChange={formik.handleChange}
                    />
                    <label className="ml-2">Ativo ?</label>
                </div>
            </div>
            {/* <div className="md:col-6 col-6">
                <div className="flex align-items-center">
                    <Checkbox
                        onChange={(e) => setCheckIntegracao(e.checked)}
                        checked={CheckIntegracao}
                    />
                    <label className="ml-2">Integração ?</label>
                </div>
            </div> */}
            <div className="col-12">
                Foto
                <div>
                    <div className={inputClassName(formik?.errors?.foto)}>
                        <Uploader
                            // disabled={app.auth.user.prodintegra}
                            name="foto"
                            showUploadedFiles
                            value={formik.values.foto}
                            uploadPath="fileuploader/upload/foto"
                            onChange={(paths) => formik.setFieldValue("foto", paths)}
                            fileLimit={1}
                            maxFileSize={3}
                            accept=".jpg,.png,.gif,.jpeg"
                            multiple={false}
                            label="Escolha arquivos ou solte-os aqui"
                            onUploadError={(errMsg) => app.flashMsg("Upload error", errMsg, "error")}
                        />
                    </div>
                    <ErrorMessage name="foto" component="span" className="p-error" />
                </div>
            </div>
        </div>
    );
};

export default ProductForm;