import { useState, useEffect, useContext } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ExportPageData } from 'components/ExportPageData';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import PedidosEditPage from 'pages/pedidos/Edit';
import useApp from 'hooks/useApp';
import useUtils from 'hooks/useUtils';
import useListPage from 'hooks/useListPage';
import MasterDetailPages from './MasterDetailPages';
import MultiSelectGroup from 'pages/custom/MultiSelectGroup';
import Utils from 'helpers/helper.util';
import useApi from 'hooks/useApi';

const PedidosListPage = (props) => {
	const app = useApp();
	const api = useApi();
	const useutils = useUtils();
	const utils = Utils();
	const { empid, setempid } = useApp();

	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, apiUrl, currentRecord, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, setCurrentRecord, pagination } = pageController;
	const { filters, setFilterValue } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } = pagination;
	const [startDate, setStartDate] = useState(utils.FirstDateOfCurrentMonth());
	const [endDate, setEndDate] = useState(utils.LastDateOfCurrentMonth());
	const [funcionarios, setFuncionarios] = useState([]);

	useEffect(() => {
		api.get('funcionarios/index').then((response) => {
			setFuncionarios(response.data.records);
			console.log("Funcionarios", funcionarios);
		});
	}, [empid])


	function ActionButton(data) {
		const items = [
			{
				label: "Editar",
				command: (event) => { app.openPageDialog(<PedidosEditPage isSubPage apiPath={`/pedidos/edit/${data.id}`} />, { closeBtn: true }) },
				icon: "pi pi-pencil"
			},
			{
				label: "Excluir",
				command: (event) => { deleteItem(data.id) },
				icon: "pi pi-trash"
			}
		]
		let shouldShowButton = data.status == "A";
		return shouldShowButton ?
			(<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />)
			: null;
	}


	function bodyId(data) {
		if (data) {
			return (
				<Link to={`/`}> {data.id}</Link>
			);
		}
	}
	function bodyRazaoSocial(data) {
		if (data) {
			return (
				<>{data.empresas_razaosocial}</>
			);
		}
	}

	function bodyFuncionario(data) {
		if (data) {
			return (
				<>{data.funcionarios_nome}</>
			);
		}
	}

	function bodyStatus(data) {
		if (data) {
			return (
				<>
					<Chip
						label={
							app.ChooseItem(
								data.status,
								[
									{ valor: 'A', titulo: 'Aberto' },
									{ valor: 'C', titulo: 'Cancelado' },
									{ valor: 'F', titulo: 'Finalizado' }
								])
						}
					/>
				</>
			);
		}
	}

	function bodyTipo(data) {
		if (data) {
			return (
				<><Chip label={
					app.ChooseItem(
						data.tipo,
						[
							{ valor: 'E', titulo: 'Entrada' },
							{ valor: 'S', titulo: 'Saída' },
							{ valor: 'T', titulo: 'Terminal' },
						])
				}
				/>
				</>
			);
		}
	}


	const OnClickPrintPedido = (param) => {
		if (param) {
			var paramns = { id: 2, filter: `pedido|id|${param.id}|0|0` };
			app.openPageDialog(<app.PrintPreview paramns={paramns} />, { maximized: true, closeBtn: true, width: '80vw' })
		}
	};

	function bodyPrint(data) {
		if (data) {
			let shouldShowButton = data.status == "F";
			return shouldShowButton ? (
				<app.PrintButton onClick={() => OnClickPrintPedido(data)} />
			) : null;
		}
	}

	function bodyData(data) {
		if (data) {
			return app.FmtDateBR(data.dt)
		}
	}


	function MasterDetailBtnTemplate(data) {
		if (data) {
			let shouldShowButton = data.status == "A";
			return shouldShowButton ? (
				<Button
					className="p-button-text"
					//   onClick={() => setCurrentRecord(data)} 
					onClick={() => app.openPageDialog(<MasterDetailPages masterRecord={data} />, { closeBtn: true })}

					icon="pi pi-caret-down"
					label=""
				/>
			) : null;
		}
	}

	function PageLoading() {
		if (loading) {
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{ width: '50px', height: '50px' }} /> </div>
						<div className="font-bold text-lg">Carregando...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage() {
		if (pageReady && !records.length) {
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					Nenhum Registro Encontrado
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Excluir selecionado" />
				</div>
			)
		}
	}
	function ExportData() {
		if (props.exportData && records.length) {
			const downloadFileName = `${useutils.dateNow()}-pedidos`;
			return (
				<div className="m-2">
					<ExportPageData csv={false} pageUrl={apiUrl} downloadFileName={downloadFileName} butonLabel="Exportar" tooltip="Exportar" buttonIcon="pi pi-print" />
				</div>
			);
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
			const pagerReportTemplate = {
				layout: pagination.layout,
				CurrentPageReport: (options) => {
					return (
						<>
							<span className="text-sm text-gray-500 px-2">Registros <b>{recordsPosition} do {options.totalRecords}</b></span>
						</>
					);
				}
			}
			return (
				<div className="flex-grow-1">
					<Paginator first={firstRow} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} template={pagerReportTemplate} />
				</div>
			)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<MultiDelete />
				<ExportData />
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs() {
		if (props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if (apiRequestError) {
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
		<main id="PedidosListPage" className="main-page">
			{(props.showHeader) &&
				<section className="page-section mb-3">
					<div className="container-fluid">
						<div className="grid justify-content-between align-items-center">
							<div className="col">
								<Title title="Movimentação de Pedidos por Funcionários" titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" separator={false} />
							</div>

							<div className="col-12 md:col-12">

								<div className="grid align-items-center">
									<div className="col-12 md:col-8">
										<span className="p-input-icon-left w-full">
											<i className="pi pi-user" />
											<MultiSelectGroup
												name="Grupo"
												multiSelect={false}
												// CurrentData={formik.values.grupoid}
												// onChange={(e) => (formik.values.grupoid = e.value)}
												//endpoint="funcionarios/index"
												DataList={[...funcionarios]}
												fields={{ label: "nome", value: "id" }}
											/>
										</span>
									</div>

									<div className="col-12 md:col-2">
										<span className="p-input-icon-left w-full">
											<i className="pi pi-calendar" />
											<InputText
												type="date"
												value={startDate}
												// onChange={(e) => setFilterValue('startDate', e.target.value)}
												placeholder="Data Inicial"
												className="w-full"
											/>
										</span>
									</div>

									<div className="col-12 md:col-2">
										<span className="p-input-icon-left w-full">
											<i className="pi pi-calendar" />
											<InputText
												type="date"
												value={endDate}
												// onChange={(e) => setFilterValue('endDate', e.target.value)}
												placeholder="Data Final"
												className="w-full"
											/>
										</span>
									</div>

								</div>

								<div>
									<span className="p-input-icon-left w-full">
										<i className="pi pi-search" />
										<InputText
											placeholder="Pesquisa"
											className="w-full"
											value={filters.search.value}
											onChange={(e) => setFilterValue('search', e.target.value)}
										/>
									</span>
								</div>

							</div>
						</div>
					</div>
				</section>
			}
			<section className="page-section " >
				<div className="container-fluid">
					<div className="grid ">
						<div className="col comp-grid" >
							<FilterTags filterController={filterController} />
							<div >
								<PageBreadcrumbs />
								<div className="grid ">
									<div className="col">
										<div className="page-records">
											<DataTable
												lazy={true}
												loading={loading}
												selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
												value={records}
												dataKey="id"
												sortField={sortBy}
												sortOrder={sortOrder}
												onSort={onSort}
												className=" p-datatable-sm"
												stripedRows={true}
												showGridlines={false}
												rowHover={true}
												responsiveLayout="stack"
												emptyMessage={<EmptyRecordMessage />}
											>
												{/*PageComponentStart*/}
												<Column headerStyle={{ width: '5%' }} field="status" header="Impressao" body={bodyPrint} align='center'></Column>
												<Column headerStyle={{ width: '5%' }} field="status" header="Status" body={bodyStatus} sortable={true} align='center'></Column>
												<Column headerStyle={{ width: '5%' }} field="dt" header="Data/Hora" body={bodyData} sortable={true} ></Column>
												<Column headerStyle={{ width: '50%' }} header="Funcionario" body={bodyFuncionario} sortable={true} ></Column>
												{/* <Column headerStyle={{ width: '5%' }} field="tipo" header="Tipo" body={bodyTipo} sortable={true} align='center'></Column> */}

												{/* <Column headerStyle={{ width: '3rem' }} field="" body={MasterDetailBtnTemplate}  ></Column> */}
												{/* <Column headerStyle={{ width: '5%' }} field="id" header="Id" body={bodyId} sortable={true} ></Column> */}
												{/* <Column field="empresas_razaosocial" header="Empresa" body={bodyRazaoSocial} sortable={true} ></Column> */}
												{/* <Column headerStyle={{ width: '2rem' }} headerClass="text-center" body={ActionButton}></Column> */}
												{/*PageComponentEnd*/}

											</DataTable>
										</div>
										<PageFooter />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
PedidosListPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'inventario',
	apiPath: 'pedidos/index',
	routeName: 'pedidoslist',
	msgBeforeDelete: "Tem certeza de que deseja excluir este registro?",
	msgTitle: "Apagar registro",
	msgAfterDelete: "Registro excluído com sucesso",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: true,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default PedidosListPage;
