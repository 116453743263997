import * as yup from "yup";

// form validation schema
const validationSchema = yup.object().shape({
    nome: yup.string().required().label("Nome"),
    //nomereduzido: yup.string().required().label("Nome Reduzido"),
    grupoid: yup.string().required().label("Grupo"),
    //setorid: yup.string().required().label("Setor"),
    ean: yup.string().required().label("EAN (BARRAS)"),
    codexterno: yup.string().required().label("Cod Externo"),
    codfabrica: yup.string().required().label("Cod. Fabr"),
    unidade: yup.string().required().label("UND"),
    //ca: yup.string().required().label("Certificado de Aprovação (CA)"),
    validade: yup.string().required().label("Validade"),
    custo: yup.number().required().label("Custo"),
    venda: yup.number().required().label("Venda"),
    estoque: yup.number().required().label("Estoque"),
    estoquelocal: yup.number().required().label("Estoquelocal"),
    active: yup.string().required().label("Status"),
    integracao: yup.string().required().label("Integracao"),
    foto: yup.string().nullable().label("Foto"),
});


//form default values
const formDefaultValues = {
    nome: "",
    nomereduzido: "",
    grupoid: "",
    ean: "",
    codexterno: "",
    codfabrica: "",
    unidade: "UN",
    ca: "",
    validade: new Date(),
    custo: "0",
    venda: "0",
    estoque: "0",
    estoquelocal: "0",
    active: "true",
    integracao: "false",
    foto: "",
};

export { validationSchema, formDefaultValues };