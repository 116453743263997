import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Message } from 'primereact/message';
import { Password } from 'primereact/password';
import { Tooltip } from 'primereact/tooltip';
import useApp from 'hooks/useApp';
import usePostForm from 'hooks/usePostForm';
import useApi from 'hooks/useApi';

export default function ResetPassword(props) {
    const app = useApp();
    const api = useApi();

    const [searchParams] = useSearchParams();
    const { id, title } = props;
    console.log(props);
    const formUrl = "auth/resetpassword";
    let token = searchParams.get("token");
    const formData = {
        password: "102030",
        confirm_password: "102030",
        userid: id,
        token,
    }
    const validationSchema = yup.object().shape({
        password: yup.string().required().label(`Password`),
        confirm_password: yup.string().required().label(`Confirm Password`).oneOf([yup.ref('password')], 'Your passwords do not match.'),
    });
    function afterSubmit(data) {
        //app.navigate("/index/resetpassword_completed");
        //app.closePageDialog();
    }
    const form = {
        formUrl, formData, validationSchema, afterSubmit
    }
    const { loading, errorMsg, formik, getFieldError, getErrorClass } = usePostForm(form);

    const handleSubmit = (event) => {
        var resetData = 
        {
            password: formik.values.password,
            confirm_password: formik.values.confirm_password,
            userid: formik.values.userid
        }
        api.post("auth/ResetPassword", formik.values).then((data) => {
            console.log(data);
            //formik.handleSubmit(event);
            //app.closePageDialog();
        });

        event.preventDefault();
        //formik.handleSubmit(event);
    };

    return (
        <div className="container mx-auto max-w-lg">
            <div className="flex justify-center">
                <div className="w-full md:w-5/12">
                    <div className="card p-4">
                        <div className="my-3 text-center">
                            <div className="text-2xl font-bold">Gerenciador de redefinição de senha</div>
                        </div>
                        <div className="my-3 text-left">
                            <div className="text-1xl">{title}</div>
                        </div>
                        {errorMsg && <Message severity="error" text={errorMsg} />}
                        <form onSubmit={handleSubmit}>
                            <div className="formgrid mb-3">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Nova senha</label>
                                <Password
                                    inputClassName="w-full"
                                    className={classNames('w-full', getErrorClass('password'))}
                                    name="password"
                                    id="password"
                                    feedback
                                    toggleMask
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    placeholder="Digite sua nova senha"
                                />
                                <Tooltip target="#password" content="A senha deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma letra minúscula e um número." />
                                {getFieldError('password')}
                            </div>
                            <div className="formgrid mb-3">
                                <label htmlFor="confirm_password" className="block text-sm font-medium text-gray-700">Confirme a nova senha</label>
                                <Password
                                    inputClassName="w-full"
                                    className={classNames('w-full', getErrorClass('confirm_password'))}
                                    name="confirm_password"
                                    id="confirm_password"
                                    feedback={false}
                                    toggleMask
                                    value={formik.values.confirm_password}
                                    onChange={formik.handleChange}
                                    placeholder="Confirme sua nova senha"
                                />
                                <Tooltip target="#confirm_password" content="Digite a mesma senha para confirmação." />
                                {getFieldError('confirm_password')}
                            </div>
                            <div className="text-center">
                                <Button type="submit" loading={loading} label="Mudar senha" className="p-button-rounded p-button-success" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

