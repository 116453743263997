import { useEffect,useState } from 'react';
import { Chart } from 'primereact/chart';
import { DataSource } from 'components/DataSource';
import { RecordCount } from 'components/RecordCount';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useApi from 'hooks/useApi';

export default function DashboardPage() {
	const api = useApi();

    const [pageReady, setPageReady] = useState(true);
	const { empid, setempid } = useApp();
    const [ dataDashBoard, setdataDashBoard ] = useState(null)

    useEffect(  () => { 
        const fetchData = async () => {
            const response = await api.get("components_data/dashboard");
            setdataDashBoard(response);
        }
        fetchData();
    }, [empid]);


    return (
        <main id="DashboardPage" className="main-page">
            <section className="page-section mb-3" >
                <div className="container-fluid">
                    <div className="grid ">
                        <div className="col-12 comp-grid" >
                            <Title title="DashBoard" titleClass="text-lg font-bold text-primary" subTitleClass="text-500" iconClass="pi pi-chart-pie" avatarSize="large" separator={false} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section mb-3" >
                <div className="container-fluid">
                    <div className="grid">
                        <div className="col-sm col-md col comp-grid" >
                            <RecordCount title="Empresas" description="Total de Empresas" icon="pi pi-th-large" apiPath="components_data/getcount_empresas" link={`/empresas`} cardClass="bg-blue-100 text-blue-800" avatarClass="bg-blue-600 text-blue-100" />
                        </div>
                        <div className="col-sm col-md col comp-grid" >
                            <RecordCount title="Produtos" description="Total Produtos" icon="pi pi-th-large" apiPath="components_data/getcount_produtos" link={`/vprodutos`} cardClass="bg-blue-100 text-blue-800" avatarClass="bg-blue-600 text-blue-100" />
                        </div>
                        <div className="col-sm col-md col comp-grid" >
                            <RecordCount title="Estoque" description="Total de Estoque" icon="pi pi-th-large" apiPath="components_data/getcount_estoque" link={`/estoque`} cardClass="bg-blue-100 text-blue-800" avatarClass="bg-blue-600 text-blue-100" />
                        </div>
                        <div className="col-sm col-md col comp-grid" >
                            <RecordCount title="Patrimônio" description="Patrimônio" apiPath="components_data/getcount_patrimnio" link={`/vpatrimonio`} cardClass="bg-blue-100 text-blue-800" avatarClass="bg-blue-600 text-blue-100" valuePrefix="R$" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section mb-3" >
                <div className="container-fluid">
                    <div className="grid ">
                        <div className="col comp-grid" >
                            <div className="card  s">
                                <div className="q-pa-md">
                                    <div className="font-bold text-lg">Vendas por Empresas</div>
                                    <div className="text-500"></div>
                                    <hr />
                                    <div className="row q-col-gutter-sm">
                                        <div className="col">
                                            <DataSource apiPath="components_data/vendasporempresas_vendasporempresas"  >
                                                {
                                                    ({ response }) =>
                                                        <>
                                                            <Chart data={response} type="bar" options={{
                                                                
                                                            }} />
                                                        </>
                                                }
                                            </DataSource>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
