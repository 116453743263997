import { useEffect, useContext } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CanView } from "components/Can";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ExportPageData } from "components/ExportPageData";
import { FilterTags } from "components/FilterTags";
import { InputText } from "primereact/inputtext";
import { PageRequestError } from "components/PageRequestError";
import { Paginator } from "primereact/paginator";
import { ProgressSpinner } from "primereact/progressspinner";
import { SplitButton } from "primereact/splitbutton";
import { Title } from "components/Title";
import DepartamentosAddPage from "pages/departamentos/Add";
import useApp from "hooks/useApp";
import useAuth from "hooks/useAuth";
import useUtils from "hooks/useUtils";
import useListPage from "hooks/useListPage";
import DepartamentosEditPage from "./Edit";

const DepartamentosListPage = (props) => {
    const auth = useAuth();
    const app = useApp();
    const utils = useUtils();
    const { empid, setempid } = useApp();

    const filterSchema = {
        search: {
            tagTitle: "Search",
            value: "",
            valueType: "single",
            options: [],
        },
    };
    const pageController = useListPage(props, filterSchema);
    const filterController = pageController.filterController;
    const {
        records,
        pageReady,
        loading,
        selectedItems,
        apiUrl,
        sortBy,
        sortOrder,
        apiRequestError,
        setSelectedItems,
        getPageBreadCrumbs,
        onSort,
        deleteItem,
        pagination,
    } = pageController;
    const { filters, setFilterValue } = filterController;
    const {
        totalRecords,
        totalPages,
        recordsPosition,
        firstRow,
        limit,
        onPageChange,
    } = pagination;
    function ActionButton(data) {
        const items = [
            {
                label: "Editar",
				command: (event) => { app.openPageDialog(<DepartamentosEditPage isSubPage apiPath={`/departamentos/edit/${data.id}`} />, { closeBtn: true }) },
                icon: "pi pi-pencil",
                visible: () => auth.canView("departamentos/edit"),
            },
            {
                label: "Excluir",
                command: (event) => {
                    deleteItem(data.id);
                },
                icon: "pi pi-trash",
                visible: () => auth.canView("departamentos/delete"),
            },
        ].filter((item) => {
            if (item.visible) {
                return item.visible();
            }
            return true;
        });
        return (
            <SplitButton
                dropdownIcon="pi pi-bars"
                className="dropdown-only p-button-text p-button-plain"
                model={items}
            />
        );
    }
    function PageLoading() {
        if (loading) {
            return (
                <>
                    <div className="flex align-items-center justify-content-center text-gray-500 p-3">
                        <div>
                            <ProgressSpinner
                                style={{ width: "50px", height: "50px" }}
                            />{" "}
                        </div>
                        <div className="font-bold text-lg">Carregando...</div>
                    </div>
                </>
            );
        }
    }
    function EmptyRecordMessage() {
        if (pageReady && !records.length) {
            return (
                <div className="text-lg mt-3 p-3 text-center text-400 font-bold">
                    Nenhum Registro Encontrado
                </div>
            );
        }
    }
    function MultiDelete() {
        if (selectedItems.length) {
            return (
                <div className="m-2 flex-grow-0">
                    <Button
                        onClick={() => deleteItem(selectedItems)}
                        icon="pi pi-trash"
                        className="p-button-danger"
                        title="Excluir selecionado"
                    />
                </div>
            );
        }
    }
    function ExportData() {
        if (props.exportData && records.length) {
            const downloadFileName = `${utils.dateNow()}-departamentos`;
            return (
                <div className="m-2">
                    <ExportPageData
                        csv={false}
                        pageUrl={apiUrl}
                        downloadFileName={downloadFileName}
                        butonLabel="Exportar"
                        tooltip="Exportar"
                        buttonIcon="pi pi-print"
                    />
                </div>
            );
        }
    }
    function PagerControl() {
        if (props.paginate && totalPages > 1) {
            const pagerReportTemplate = {
                layout: pagination.layout,
                CurrentPageReport: (options) => {
                    return (
                        <>
                            <span className="text-sm text-gray-500 px-2">
                                Registros{" "}
                                <b>
                                    {recordsPosition} do {options.totalRecords}
                                </b>
                            </span>
                        </>
                    );
                },
            };
            return (
                <div className="flex-grow-1">
                    <Paginator
                        first={firstRow}
                        rows={limit}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        template={pagerReportTemplate}
                    />
                </div>
            );
        }
    }
    function PageActionButtons() {
        return (
            <div className="flex flex-wrap">
                <CanView pagePath="departamentos/delete">
                    <MultiDelete />
                </CanView>
                <ExportData />
            </div>
        );
    }
    function PageFooter() {
        if (pageReady && props.showFooter) {
            return (
                <div className="flex flex-wrap">
                    <PageActionButtons />
                    <PagerControl />
                </div>
            );
        }
    }
    function PageBreadcrumbs() {
        if (props.showBreadcrumbs) {
            const items = getPageBreadCrumbs();
            return (
                items.length > 0 && (
                    <BreadCrumb className="mb-3" model={items} />
                )
            );
        }
    }
    if (apiRequestError) {
        return <PageRequestError error={apiRequestError} />;
    }
    return (
        <main id="DepartamentosListPage" className="main-page">
            {props.showHeader && (
                <section className="page-section mb-3">
                    <div className="container-fluid">
                        <div className="grid justify-content-between align-items-center">
                            <div className="col ">
                                <Title
                                    title="Centro de Custos"
                                    titleClass="text-2xl text-primary font-bold"
                                    subTitleClass="text-500"
                                    separator={false}
                                />
                            </div>
                            <div className="col-fixed ">
                                <CanView pagePath="departamentos/add">
                                    <Button
                                        label="Adicionar novo"
                                        icon="pi pi-plus"
                                        onClick={() =>
                                            app.openPageDialog(
                                                <DepartamentosAddPage
                                                    isSubPage
                                                    apiPath={`/departamentos/add`}
                                                />,
                                                { closeBtn: true }
                                            )
                                        }
                                        className="p-button w-full bg-primary "
                                    />
                                </CanView>
                            </div>
                            <div className="col-12 md:col-3 ">
                                <span className="p-input-icon-left w-full">
                                    <i className="pi pi-search" />
                                    <InputText
                                        placeholder="Pesquisa"
                                        className="w-full"
                                        value={filters.search.value}
                                        onChange={(e) =>
                                            setFilterValue(
                                                "search",
                                                e.target.value
                                            )
                                        }
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            <section className="page-section ">
                <div className="container-fluid">
                    <div className="grid ">
                        <div className="col comp-grid">
                            <FilterTags filterController={filterController} />
                            <div>
                                <PageBreadcrumbs />
                                <div className="page-records">
                                    <DataTable
                                        lazy={true}
                                        loading={loading}
                                        value={records}
                                        dataKey="id"
                                        sortField={sortBy}
                                        sortOrder={sortOrder}
                                        onSort={onSort}
                                        className=" p-datatable-sm"
                                        stripedRows={true}
                                        showGridlines={false}
                                        rowHover={true}
                                        responsiveLayout="stack"
                                        emptyMessage={<EmptyRecordMessage />}
                                    >
                                        {/*PageComponentStart*/}
                                        <Column
                                            headerStyle={{ width: "2rem" }}
                                        ></Column>
                                        <Column
                                            field="nome"
                                            header="Nome"
                                            sortable={true}
                                        ></Column>
                                        <Column
                                            headerStyle={{ width: "2rem" }}
                                            headerClass="text-center"
                                            body={ActionButton}
                                        ></Column>
                                        {/*PageComponentEnd*/}
                                    </DataTable>
                                </div>
                                <PageFooter />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
DepartamentosListPage.defaultProps = {
    primaryKey: "id",
    pageName: "Centro de Custo",
    apiPath: "departamentos/index",
    routeName: "departamentoslist",
    msgBeforeDelete: "Tem certeza de que deseja excluir este registro?",
    msgTitle: "Apagar registro",
    msgAfterDelete: "Registro excluído com sucesso",
    showHeader: true,
    showFooter: true,
    paginate: true,
    isSubPage: false,
    showBreadcrumbs: true,
    exportData: true,
    importData: false,
    keepRecords: false,
    multiCheckbox: true,
    search: "",
    fieldName: null,
    fieldValue: null,
    sortField: "",
    sortDir: "",
    pageNo: 1,
    limit: 10,
};
export default DepartamentosListPage;
