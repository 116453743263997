import React, { useState, useEffect } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import useApi from 'hooks/useApi';

const MultiSelectGroup = ({ CurrentData = [], DataList = [], onChange, endpoint = null, fields = [], multiSelect = true, name = "" }) => {
    const api = useApi();

    const [GroupList, setGroupList] = useState([]);
    const [SelectedGroups, setSelectedGroups] = useState([]);
    const [SelectedItem, setSelectedItem] = useState(null);
    const _label = fields.label ? fields.label : "label";
    const _value = fields.value ? fields.value : "value";

    // Load List API
    useEffect(() => {
        // Retorna com os Grupos
        if (DataList && DataList.length > 0) {
            setGroupList([...DataList]); // Use spread operator to copy by reference
        }
        else {
            const fetchDataGrupsList = async () => {
                var _endpoint = endpoint ? endpoint : 'components_data/grupoid_option_list_2';
                const response = await api.get(_endpoint);
                setGroupList(response.data);
            };
            fetchDataGrupsList();
        }
        if (CurrentData && CurrentData.length > 0) {
            setSelectedGroups([...CurrentData]); // Use spread operator to copy by reference
            setSelectedItem([...CurrentData]);
        }
    }
        , [DataList, CurrentData]); // Add dependencies to ensure updates

    const onHandleChange = (e) => {
        setSelectedGroups(e.value);
        setSelectedItem(e.value);
        if (onChange)  // Se existir a função onChange, chama ela
            onChange(e);
    };

    return (
        <>
            {multiSelect ? (
                <MultiSelect
                    options={GroupList}
                    value={SelectedGroups}
                    onChange={onHandleChange}
                    optionLabel={_label}
                    optionValue={_value}
                    filter
                    placeholder="Selecione os Grupos"
                    //maxSelectedLabels={3}
                    display="chip"
                    style={{ width: "100%" }}
                />
            ) : (
                <Dropdown
                    name={name}
                    filter
                    value={SelectedItem}
                    options={GroupList}
                    optionLabel={_label}
                    optionValue={_value}
                    onChange={onHandleChange}
                    style={{ width: "100%" }}
                />
            )}
        </>
    );
};

export default MultiSelectGroup;
