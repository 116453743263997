import React, { useRef, useEffect, useState } from "react";
import useApp from "hooks/useApp";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import useApi from "hooks/useApi";
import { Button } from "primereact/button";
import GridComponents from "./Grid.Components";
import GridMethods from "./Grid.Methods";

export default function GHE() {
    const api = useApi();
    const gridComponent = new GridComponents();
    const gridMethods = new GridMethods();

    const dt = useRef(null); // Ref DataTable

    const { empid } = useApp();
    const [SelectedGroup, setSelectedGroup] = useState(null);
    const [GroupList, setGroupList] = useState(null);
    const [ListProdutos, setListProdutos] = useState(null);
    const [ListGridProducts, setListGridProducts] = useState(null);
    const [SelectedProducts, setSelectedProducts] = useState(null);
    const [ProdutosList, setProdutosList] = useState([]);
    const [EditingRows, setEditingRows] = useState({});

    // Fetch products and groups on component mount
    useEffect(() => {
        gridMethods.fetchProdutosAsync().then((data) => {
            setProdutosList(data);
        });

        gridMethods.fetchDataGrupsList().then((data) => {
            setGroupList(data);
        }).catch((error) => {
            console.error("Erro ao buscar produtos:", error);
        });
    }, []);

    // Fetch groups when empid changes
    useEffect(() => {
        gridMethods.fetchDataGrupsList().then((data) => {
            setGroupList(data);
            setListGridProducts(null);
        }).catch((error) => {
            console.error("Erro ao buscar produtos:", error);
        });
    }, [empid]);

    const HandleGestorProductList = async (rowData) => {
        gridMethods.fetchGestorProductList(rowData.value).then((data) => {
            setSelectedGroup(rowData.value);
            setListGridProducts(data.map(item => ({
                ...item,
                id: item.prodid,
                isEditing: false
            })));
            setSelectedProducts(null);
        }).catch((error) => {
            console.error("Erro ao buscar produtos:", error);
        });
    };

    const HandleRowEditComplete = (rowData) => {
        let { newData, index } = rowData;
        if (!newData.prodid) return; // Avoid error when saving without selecting a product
        // Save the row
        gridMethods.OnRowEditSave(newData).then((data) => {
            // Update the row in the grid
            let _ListGridProducts = [...ListGridProducts];
            _ListGridProducts[index] = newData;
            setListGridProducts(_ListGridProducts);

            const produtoSelecionado = ProdutosList.find((x) => x.id === newData.prodid);
            if (produtoSelecionado) {
                newData.prodNome = produtoSelecionado.nome;
            }

            stopEditing(newData);
        }).catch((error) => {
            console.error("Erro ao salvar:", error);
        });

    };

    const stopEditing = (rowData) => {
        setEditingRows((prev) => {
            const newEditingRows = { ...prev };
            delete newEditingRows[rowData.id];
            rowData.isEditing = false;
            return newEditingRows;
        });
    };

    const allowEdit = (rowData) => {
        return EditingRows[rowData.id];
    };

    const HandleAddNewProduct = async () => {
        console.log("Adicionando nova linha");
        const response = await gridMethods.OnClickAddNewRow(SelectedGroup, ListGridProducts, setListGridProducts);
        //if (response.success) {
            //setListGridProducts(response.data);
        //}
    };

    async function HandeExcludeSelectedProducts() {
        const confirmed = await gridMethods.OnClickExcludeSelection(SelectedProducts);
        if (confirmed.success == true) {
            const listIds = SelectedProducts.map(item => item.prodid);
            const newListGridProducts = ListGridProducts.filter(item => !listIds.includes(item.prodid));
            setListGridProducts(newListGridProducts);
            setSelectedProducts(null);
        }
    };

    const handleClickSelectProductGroup = async () => {
        await gridMethods.OnClickSelectProductGroup(SelectedGroup)
    }

    return (
        <div className="card p-fluid">
            <div className="grid">
                <div className="col-4">
                    <Dropdown
                        value={SelectedGroup}
                        onChange={HandleGestorProductList}
                        options={GroupList}
                        optionValue="id"
                        optionLabel="title"
                        placeholder="Grupo Gestor"
                        className="w-full"
                    />
                </div>

                <div className="col-6">
                    <div className="grid">
                        <div className="col-1" style={{ padding: "0 5px" }}>
                            <Button
                                icon="pi pi-refresh"
                                tooltip="Atualizar"
                                disabled={ListGridProducts == null}
                                tooltipOptions={{ position: "bottom" }}
                            />
                        </div>

                        <div className="col-1" style={{ padding: "0 5px" }}>
                            <Button
                                icon="pi pi-plus"
                                aria-label="Filter"
                                tooltip="Adiciona um novo Grupo"
                                tooltipOptions={{ position: "bottom" }}
                                onClick={() => gridMethods.OnClickAddGroup()}
                            />
                        </div>

                        <div className="col-1" style={{ padding: "0 5px" }}>
                            <Button
                                icon="pi pi-pencil"
                                tooltip="Edita o Grupo"
                                disabled={ListGridProducts == null}
                                tooltipOptions={{ position: "bottom" }}
                                onClick={() => gridMethods.OnClickEditGroup(SelectedGroup)}
                            />
                        </div>

                        <div className="col-1" style={{ padding: "0 5px" }}>
                            <Button
                                icon="pi pi-trash"
                                tooltip="Exclui o Grupo"
                                disabled={ListGridProducts == null}
                                tooltipOptions={{ position: "bottom" }}
                            />
                        </div>

                        <div className="col-1" style={{ padding: "0 5px" }}>
                            <Button
                                icon="pi pi-box"
                                severity="warning"
                                tooltip="Adiciona um produto ao GHE Selecionado"
                                disabled={ListGridProducts == null}
                                tooltipOptions={{ position: "bottom" }}
                                onClick={(e) => HandleAddNewProduct()}
                            />
                        </div>

                        <div className="col-1" style={{ padding: "0 5px" }}>
                            <Button
                                icon="pi pi-cart-plus"
                                tooltip="Vincula um Agrupamento ao GHE Selecionado"
                                disabled={ListGridProducts == null}
                                tooltipOptions={{ position: "bottom" }}
                                onClick={() => handleClickSelectProductGroup()}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <DataTable
                ref={dt}
                value={ListGridProducts}
                selection={SelectedProducts}
                editMode="row"
                dataKey="id"
                selectionMode={'checkbox'}
                onSelectionChange={(e) => setSelectedProducts(e.value)}
                onRowEditComplete={HandleRowEditComplete}
                tableStyle={{ minWidth: "50rem" }}
                onRowEditChange={(rowData) => setEditingRows(rowData.data)}
                editingRows={EditingRows} // Edita a linha
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column
                    header="Produto"
                    field="prodid"
                    body={(rowData) => rowData.prodNome}
                    editor={(rowData) => gridComponent.edProduto(rowData, ProdutosList)}
                    style={{ width: "70%" }}
                ></Column>

                <Column
                    field="qtde"
                    header="Quantidade"
                    editor={gridComponent.edComboQtde}
                    style={{ width: "10%" }}
                ></Column>

                <Column
                    field="periodic"
                    header="Periodicidade"
                    editor={gridComponent.edPeriodic}
                    style={{ width: "10%" }}
                ></Column>

                <Column
                    rowEditor={allowEdit}
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    bodyStyle={{ textAlign: "center" }}
                //body={(rowData, options) => gridComponent.ActionButton(rowData, { ...options, startEditing, stopEditing, onRowEditComplete })}
                ></Column>

            </DataTable>
            <div className="grid">
                <div className="col-12">
                    <div className="col-1" style={{ paddingLeft: "5px", paddingRight: "0px" }}>
                        <Button
                            icon="pi pi-trash"
                            tooltip="Excluir Seleção"
                            visible={SelectedProducts != null && SelectedProducts.length > 0}
                            tooltipOptions={{ position: "bottom" }}
                            onClick={(e) => HandeExcludeSelectedProducts()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
