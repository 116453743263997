import React, { useState, useEffect, useContext } from "react";
import { Title } from "components/Title";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataSource } from "components/DataSource";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import useApi from "hooks/useApi";
import useUtils from "hooks/useUtils";
import useApp from "hooks/useApp";

export default function KardexDetailPage() {
    const app = useApp();
    const api = useApi();
    const utils = useUtils();
    const { empid, setempid } = useApp();

    const [pageReady, setPageReady] = useState(true);
    const [ProdID, setProdID] = useState();
    const [disableFilter, setdisableFilter] = useState(true);
    const [MotTipo, setMotTipo] = useState("T");
    const [DataI, setDataI] = useState("T");
    const [DataF, setDataF] = useState("T");
    const [TitlePage, setTitlePage] = useState("Detalhe da Movimentacao");
    const [loading, setloading] = useState(false);
    const [DadosKardexDetail, setDadosKardexDetail] = useState([]);
    const [ListProdsKardex, setListProdsKardex] = useState([]);

    useEffect(() => {
        const hoje = new Date();
        const ano = hoje.getFullYear();
        const mes = hoje.getMonth();

        const primeiroDia = new Date(ano, mes, 1);
        setDataI(primeiroDia);
        const ultimoDia = new Date(ano, mes + 1, 0); // Dia 0 do próximo mês é o último dia do mês atual
        setDataF(ultimoDia);
    }, []);

    useEffect(() => {
        //LoadProdsKardex();
    }, [empid]);

    const [filtro, setFiltro] = useState({
        produto: "",
        dataInicial: null,
        dataFinal: null,
        tipoMovimentacao: null,
    });

    function bodyDate(data) {
        if (data) {
            return <>{app.FmtDateBR(data.data)}</>;
        }
    }

    const carregarDadosKardexDetail = async () => {
        try {
            if (DataI > DataF) {
                window.alert(
                    "A data final não pode ser menor que a data inicial"
                );
                //Usar um componente
                return;
            }
            setloading(true);
            let formPost = {
                prodid: ProdID,
                datainicial: DataI,
                datafinal: DataF,
            };
            let response = await api.post(`pedidos/kardexdetail`, formPost);
            setDadosKardexDetail(response.data);
            setloading(false);
        } catch (error) {
            console.clear();
            console.error(error);
        }
    };

    useEffect(() => {
        //ListProdsKardex
    }, []);

    const headGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Data" rowSpan={2} alignHeader="center" />
                <Column
                    header="Estoque Movimentado do Dia"
                    colSpan={7}
                    alignHeader="center"
                />
            </Row>
            <Row>
                <Column header="Tipo" rowSpan={1} alignHeader="center" />
                <Column header="Produto" rowSpan={1} alignHeader="center" />
                <Column header="Nome" rowSpan={1} alignHeader="center" />
                <Column header="Unidade" rowSpan={1} alignHeader="center" />
                <Column header="Unitário" rowSpan={1} alignHeader="center" />
                <Column header="Anterior" rowSpan={1} alignHeader="center" />
                <Column header="Quantidade" rowSpan={1} alignHeader="center" />
            </Row>
        </ColumnGroup>
    );

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold">Filtragem</span>

            <div className="container">
                <div className="grid">
                    <div className="md: col">

                      
                        <DataSource apiPath="components_data/prodid_option_list_by_kardex">
                            {({ response }) => (
                                <>
                                    Produto
                                    <Dropdown
                                        style={{ width: "100%" }}
                                        filter
                                        name="prodid"
                                        optionLabel="label"
                                        optionValue="value"
                                        value={ProdID}
                                        onChange={(e) => {
                                            setProdID(e.value);
                                            setdisableFilter(false);
                                        }}
                                        options={response}
                                        label="Produto"
                                        placeholder="Selecione um Produto ..."
                                    />
                                </>
                            )}
                        </DataSource>
                    </div>

                    <div className="md: col ">
                        <label
                            style={{ display: "inline-block", width: "74px" }}
                        >
                            Data Inicial
                        </label>
                        <Calendar
                            style={{ width: "100%" }}
                            dateFormat="dd/mm/yy"
                            placeholder="Data Inicial"
                            value={DataI}
                            onChange={(e) => setDataI(e.value)}
                        />
                    </div>

                    <div className=" md: col">
                        <label
                            style={{ display: "inline-block", width: "70px" }}
                        >
                            {" "}
                            Data Final
                        </label>
                        <Calendar
                            style={{ width: "100%" }}
                            dateFormat="dd/mm/yy"
                            placeholder="Data Final"
                            value={DataF}
                            onChange={(e) => setDataF(e.value)}
                        />
                    </div>

                    <div className="md: col">
                        <Button
                            label="Filtrar"
                            onClick={carregarDadosKardexDetail}
                            disabled={disableFilter}
                            icon="pi pi-refresh"
                            style={{ width: "100%", marginTop: "15px" }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div style={{ marginBottom: "50px" }}>
                <h1>{TitlePage}</h1>
            </div>

            <section className="page-section">
                <div className="container-fluid">
                    <div className="grid">
                        <div className="col comp-grid">
                            {/* <PageBreadcrumbs /> */}
                            <div className="page-records">
                                <DataTable
                                    header={header}
                                    headerColumnGroup={headGroup}
                                    lazy={true}
                                    loading={loading}
                                    value={DadosKardexDetail}
                                    dataKey="id"
                                    className=" p-datatable-sm md: row"
                                    stripedRows={true}
                                    showGridlines={false}
                                    rowHover={true}
                                    responsiveLayout="stack"
                                    //emptyMessage={<EmptyRecordMessage />}
                                >
                                    {/*PageComponentStart*/}
                                    {/* <Column headerStyle={{ width: '20%' }} body={bodyDate} ></Column> */}
                                    <Column
                                        headerStyle={{ width: "20%" }}
                                        field="dt"
                                        align="center"
                                        header="Data"
                                    ></Column>
                                    <Column
                                        headerStyle={{ width: "20%" }}
                                        field="tipo"
                                        align="center"
                                        header="Tipo"
                                    ></Column>
                                    <Column
                                        headerStyle={{ width: "20%" }}
                                        field="prodid"
                                        align="center"
                                        header="Produto"
                                    ></Column>
                                    <Column
                                        headerStyle={{ width: "20%" }}
                                        field="prodnome"
                                        align="center"
                                        header="Nome"
                                    ></Column>
                                    <Column
                                        headerStyle={{ width: "20%" }}
                                        field="und"
                                        align="center"
                                        header="Unidade"
                                    ></Column>
                                    <Column
                                        headerStyle={{ width: "20%" }}
                                        field="unit"
                                        align="center"
                                        header="Unitário"
                                    ></Column>
                                    <Column
                                        headerStyle={{ width: "20%" }}
                                        field="estant"
                                        align="center"
                                        header="Estoque Anterior"
                                    ></Column>
                                    <Column
                                        headerStyle={{ width: "20%" }}
                                        field="qtde"
                                        align="center"
                                        header="Quantidade"
                                    ></Column>

                                    {/*PageComponentEnd*/}
                                </DataTable>
                            </div>
                            {/* <PageFooter /> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
