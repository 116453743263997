import * as yup from "yup";


const validationSchema = yup.object().shape({
    nome: yup.string().required().label("Nome"),
    grupoids: yup.array().of(yup.number().required()).required().label("grupoids"),
    matricula: yup.string().nullable().label("Matricula"),

    // apelido: yup.string().nullable().label("Apelido"),
    // sexo: yup.string().required().label("Sexo"),
    // cpf: yup.string().min(11).min(11).required().label("CPF"),
    // rg: yup.string().required().label("RG"),
    // cracha: yup.string().required().label("Cracha"),
    // crachaemissao: yup.string().required().label("Emissao Cracha"),
    // cep: yup.string().required().label("CEP"),
    // logradouro: yup.string().required().label("Logradouro"),
    // munid: yup.string().nullable().label("Município"),
    // funcaoid: yup.string().required().label("Função"),
    setorid: yup.string().required().label("Setor"),
    deptid: yup.string().required().label("Departamento"),
    //grupoid: yup.string().required().label("Grupo"),
    // mail: yup.string().email().required().label("Mail"),
    // dataadmissao: yup.string().required().label("Dataadmissao"),
    // datademissao: yup.string().required().label("Datademissao"),
    // active: yup.string().required().label("Status"),
    // foto: yup.string().nullable().label("Foto"),
});

    //form default values
const formDefaultValues = {
    nome: "",
    apelido: "",
    sexo: "M",
    cpf: "",
    rg: "",
    cracha: "",
    crachaemissao: new Date(),
    cep: "",
    logradouro: "",
    munid: "",
    funcaoid: "",
    setorid: "",
    deptid: "",
    mail: "",
    grupoid: "",
    dataadmissao: new Date(),
    active: "true",
    foto: "",
};


export {validationSchema, formDefaultValues};