import React from 'react';

const StatusBarIcons = ({ icons, title }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '10px' }}>
      <div style={{ marginLeft: '10px', fontWeight: 'bold', alignSelf: 'center' }}>{title}</div>
      {icons.map((icon, index) => (
        <div
          key={index}
          style={{
            color: icon.color,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: '10px', // Espaçamento entre os ícones
          }}
        >
          <span title={icon.hint}>
            <i className={icon.icon} style={{ fontSize: '24px' }}></i> {/* Use a tag <i> ou o componente de ícone de sua biblioteca */}
          </span>
        </div>
      ))}
    </div>
  );
};

export default StatusBarIcons;