import { useContext, useState, useEffect } from "react";
import { Formik, Form, ErrorMessage, useField } from "formik";
import * as yup from "yup";
import { Button } from "primereact/button";
import { DataSource } from "components/DataSource";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { ProgressSpinner } from "primereact/progressspinner";
import { Title } from "components/Title";
import useApp from "hooks/useApp";
import useAddPage from "hooks/useAddPage";

const GruposAddPage = (props) => {
    const app = useApp();
    //form validation rules
    const validationSchema = yup.object().shape({
        nome: yup.string().required().label("Nome"),
    });

    //form default values
    const formDefaultValues = {
        nome: "",
    };
    const OnClickSubmit = async (event, formik) => {
        handleSubmit(event);
    };

    //page hook where logics resides
    const pageController = useAddPage({
        props,
        formDefaultValues,
        afterSubmit,
    });
    // destructure and grab what the page needs
    const {
        formData,
        resetForm,
        handleSubmit,
        submitForm,
        pageReady,
        loading,
        saving,
        inputClassName,
    } = pageController;

    useEffect(() => {}, []);

    //event raised after form submit
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        resetForm();
        if (app.isDialogOpen()) {
            app.closeDialogs(); // if page is open as dialog, close dialog
        } else if (props.redirect) {
            app.navigate(`/grupos`);
        }
    }

    // page loading form data from api
    if (loading) {
        return (
            <div className="p-3 text-center">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
            </div>
        );
    }

    //page has loaded any required data and ready to render
    if (pageReady) {
        return (
            <main id="GruposAddPage" className="main-page">
                {props.showHeader && (
                    <section className="page-section mb-3">
                        <div className="container">
                            <div className="grid justify-content-between align-items-center">
                                {!props.isSubPage && (
                                    <div className="col-fixed ">
                                        <Button
                                            onClick={() => app.navigate(-1)}
                                            label=""
                                            className="p-button p-button-text "
                                            icon="pi pi-arrow-left"
                                        />
                                    </div>
                                )}
                                <div className="col ">
                                    <Title
                                        title="Adicionar novo"
                                        titleClass="text-2xl text-primary font-bold"
                                        subTitleClass="text-500"
                                        separator={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                <section className="page-section ">
                    <div className="container">
                        <div className="grid ">
                            <div className="md:col-9 sm:col-12 comp-grid">
                                <div>
                                <Formik
                                        initialValues={formData}
                                        validationSchema={validationSchema}
                                        onSubmit={(values) => {
                                            submitForm(values);
                                        }}
                                    >
                                        {(formik) => {
                                            return (
                                                <Form
                                                    className={`${
                                                        !props.isSubPage
                                                            ? "card  "
                                                            : ""
                                                    }`}
                                                >
                                                    <div className="grid">
                                                        <div className="col-12">
                                                            Nome *
                                                            <div>
                                                                <InputText
                                                                    name="nome"
                                                                    onChange={
                                                                        formik.handleChange
                                                                    }
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .nome
                                                                    }
                                                                    label="Nome"
                                                                    type="text"
                                                                    placeholder="Entrar Nome"
                                                                    className={inputClassName(
                                                                        formik
                                                                            ?.errors
                                                                            ?.nome
                                                                    )}
                                                                />
                                                                <ErrorMessage
                                                                    name="nome"
                                                                    component="span"
                                                                    className="p-error"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {props.showFooter && (
                                                        <div className="text-center my-3">
                                                            <Button
                                                                onClick={(e) =>
                                                                    handleSubmit(
                                                                        e,
                                                                        formik
                                                                    )
                                                                }
                                                                type="submit"
                                                                label="Atualizar"
                                                                icon="pi pi-send"
                                                                loading={saving}
                                                            />
                                                        </div>
                                                    )}
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
};

//page props and default values
GruposAddPage.defaultProps = {
    primaryKey: "id",
    pageName: "grupos",
    apiPath: "grupos/add",
    routeName: "gruposadd",
    submitButtonLabel: "Enviar",
    formValidationError: "O formulário é inválido",
    formValidationMsg: "Por favor, preencha o formulário",
    msgTitle: "Criar registro",
    msgAfterSave: "Registro adicionado com sucesso",
    msgBeforeSave: "Deseja Salvar os Dados Agora?",
    showHeader: true,
    showFooter: true,
    redirect: true,
    isSubPage: false,
};
export default GruposAddPage;
