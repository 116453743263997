import { useContext, useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { DataSource } from "components/DataSource";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import { Title } from "components/Title";
import { Uploader } from "components/Uploader";
import useApp from "hooks/useApp";
import useAddPage from "hooks/useAddPage";
import MultiSelectGroup from "../custom/MultiSelectGroup";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import ProductForm from "./Product.Form";
import { validationSchema, formDefaultValues } from "./Product.Validation";

const ProdutosAddPage = (props) => {
    const app = useApp();

    //page hook where logics resides
    const pageController = useAddPage({
        props,
        formDefaultValues,
        afterSubmit,
    });
    // destructure and grab what the page needs
    const {
        formData,
        resetForm,
        handleSubmit,
        submitForm,
        pageReady,
        loading,
        saving,
        inputClassName,
    } = pageController;
    const [CheckActive, setCheckActive] = useState(false);
    const [CheckIntegracao, setCheckIntegracao] = useState(false);

    const ListQtde = new Array(10)
        .fill(0)
        .map((_, index) => ({ name: index + 1, value: index + 1 }));
    const [SelectedQtde, setSelectedQtde] = useState(1);
    const [SelectedPeriodo, setSelectedPeriodo] = useState(30);

    //event raised after form submit
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        resetForm();
        if (app.isDialogOpen()) {
            app.closeDialogs(); // if page is open as dialog, close dialog
        } else if (props.redirect) {
            app.navigate(`/produtos`);
        }
    }

    // page loading form data from api
    if (loading) {
        return (
            <div className="p-3 text-center">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
            </div>
        );
    }

    //page has loaded any required data and ready to render
    if (pageReady) {
        return (
            <main id="ProdutosAddPage" className="main-page">
                {props.showHeader && (
                    <section className="page-section mb-3">
                        <div className="container">
                            <div className="grid justify-content-between align-items-center">
                                {!props.isSubPage && (
                                    <div className="col-fixed ">
                                        <Button
                                            onClick={() => app.navigate(-1)}
                                            label=""
                                            className="p-button p-button-text "
                                            icon="pi pi-arrow-left"
                                        />
                                    </div>
                                )}
                                <div className="col ">
                                    <Title
                                        title="Adicionar novo"
                                        titleClass="text-2xl text-primary font-bold"
                                        subTitleClass="text-500"
                                        separator={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                <section className="page-section ">
                    <div className="container">
                        <div className="grid ">
                            <div className="md:col-9 sm:col-12 comp-grid">
                                <div>
                                    <Formik
                                        initialValues={formData}
                                        validationSchema={validationSchema}
                                        onSubmit={(values, actions) => {
                                            values.active = CheckActive;
                                            values.integracao = CheckIntegracao;
                                            submitForm(values);
                                        }}
                                    >
                                        {(formik) => (
                                            <>
                                                <Form
                                                    className={`${!props.isSubPage
                                                            ? "card  "
                                                            : ""
                                                        }`}
                                                >
                                                    <ProductForm
                                                        formik={formik}
                                                        formData={
                                                            formDefaultValues
                                                        }
                                                        inputClassName={
                                                            inputClassName
                                                        }
                                                        app={app}
                                                        setCheckActive={
                                                            setCheckActive
                                                        }
                                                        CheckActive={
                                                            CheckActive
                                                        }
                                                        setCheckIntegracao={
                                                            setCheckIntegracao
                                                        }
                                                        CheckIntegracao={
                                                            CheckIntegracao
                                                        }
                                                    />

                                                    {props.showFooter && (
                                                        <div className="text-center my-3">
                                                            <Button
                                                                onClick={(e) =>
                                                                    handleSubmit(
                                                                        e,
                                                                        formik
                                                                    )
                                                                }
                                                                className="p-button-primary"
                                                                type="submit"
                                                                label="Enviar"
                                                                icon="pi pi-send"
                                                                loading={saving}
                                                            />
                                                        </div>
                                                    )}
                                                </Form>
                                            </>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
};

//page props and default values
ProdutosAddPage.defaultProps = {
    primaryKey: "id",
    pageName: "produtos",
    apiPath: "produtos/add",
    routeName: "produtosadd",
    submitButtonLabel: "Enviar",
    formValidationError: "O formulário é inválido",
    formValidationMsg: "Por favor, preencha o formulário",
    msgTitle: "Criar registro",
    msgAfterSave: "Registro adicionado com sucesso",
    msgBeforeSave: "Deseja Salvar os Dados Agora?",
    showHeader: true,
    showFooter: true,
    redirect: true,
    isSubPage: false,
};
export default ProdutosAddPage;
