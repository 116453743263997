import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { ErrorMessage } from 'formik';
import { Uploader } from 'components/Uploader';
import { Dropdown } from "primereact/dropdown";
import { DataSource } from "components/DataSource";
import { MultiSelect } from "primereact/multiselect";
import { parseJSON } from "date-fns";
import MultiSelectGroup from "../custom/MultiSelectGroup";

const  FuncionarioForm = ({ 
        formik, formData, inputClassName, app, 
        setCheckActive, CheckActive,  
        setCheckGestor, CheckGestor
    }) => {
    return (
        <div className="grid">
            <div className="md:col-6 col-12">
                Nome *
                <div>
                    <InputText
                        name="nome"
                        onChange={
                            formik.handleChange
                        }
                        value={
                            formik
                                .values
                                .nome
                        }
                        label="Nome"
                        type="text"
                        placeholder="Entrar Nome"
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.nome
                        )}
                    />
                    <ErrorMessage
                        name="nome"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>
            <div className="md:col-3 col-12">
                Apelido
                <div>
                    <InputText
                        name="apelido"
                        onChange={
                            formik.handleChange
                        }
                        value={
                            formik
                                .values
                                .apelido
                        }
                        label="Apelido"
                        type="text"
                        placeholder="Entrar Apelido"
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.apelido
                        )}
                    />
                    <ErrorMessage
                        name="apelido"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>
            <div className="md:col-3 col-12">
                Sexo *
                <div>
                    <Dropdown
                        name="sexo"
                        optionLabel="label"
                        optionValue="value"
                        value={
                            formik
                                .values
                                .sexo
                        }
                        onChange={
                            formik.handleChange
                        }
                        options={
                            app
                                .menus
                                .sexo
                        }
                        label="Sexo"
                        placeholder="Selecione um valor ..."
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.sexo
                        )}
                    />
                    <ErrorMessage
                        name="sexo"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>
            <div className="md:col-2 col-12">
                MATRICULA
                <div>
                    <InputText
                        name="matricula"
                        onChange={
                            formik.handleChange
                        }
                        value={
                            formik
                                .values
                                .matricula
                        }
                        label="Matricula"
                        type="text"
                        placeholder="Entrar com a Matricula"
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.matricula
                        )}
                    />
                    <ErrorMessage
                        name="matricula"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>
            <div className="md:col-2 col-12">
                Cracha *
                <div>
                    <InputText
                        name="cracha"
                        onChange={
                            formik.handleChange
                        }
                        value={
                            formik
                                .values
                                .cracha
                        }
                        label="Cracha"
                        type="text"
                        placeholder="Entrar Cracha"
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.cracha
                        )}
                    />
                    <ErrorMessage
                        name="cracha"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>
            <div className="md:col-3 col-12">
                Emissao Cracha *
                <div>
                    <Calendar
                        name="crachaemissao"
                        showButtonBar
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.crachaemissao
                        )}
                        dateFormat="yy-mm-dd"
                        value={
                            formik
                                .values
                                .crachaemissao
                        }
                        onChange={
                            formik.handleChange
                        }
                        showIcon
                    />
                    <ErrorMessage
                        name="crachaemissao"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>
            <div className="md:col-3 col-12">
                CPF *
                <div>
                    <InputText
                        name="cpf"
                        onChange={
                            formik.handleChange
                        }
                        value={
                            formik
                                .values
                                .cpf
                        }
                        label="CPF"
                        type="text"
                        placeholder="Entrar CPF"
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.cpf
                        )}
                    />
                    <ErrorMessage
                        name="cpf"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>
            <div className="md:col-2 col-12">
                RG *
                <div>
                    <InputText
                        name="rg"
                        onChange={
                            formik.handleChange
                        }
                        value={
                            formik
                                .values
                                .rg
                        }
                        label="RG"
                        type="text"
                        placeholder="Entrar RG"
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.rg
                        )}
                    />
                    <ErrorMessage
                        name="rg"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>
            <div className="md:col-3 col-12">
                CEP *
                <div>
                    <InputText
                        name="cep"
                        onChange={
                            formik.handleChange
                        }
                        value={
                            formik
                                .values
                                .cep
                        }
                        label="CEP"
                        type="text"
                        placeholder="Entrar CEP"
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.cep
                        )}
                        onInput={(event) => {
                            event.target.value = event.target.value.replace(/[^0-9]/g, '');
                        }}                        
                    />
                    <ErrorMessage
                        name="cep"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>
            <div className="md:col-9 col-12">
                Logradouro *
                <div>
                    <InputText
                        name="logradouro"
                        onChange={
                            formik.handleChange
                        }
                        value={
                            formik
                                .values
                                .logradouro
                        }
                        label="Logradouro"
                        type="text"
                        placeholder="Entrar Logradouro"
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.logradouro
                        )}
                    />
                    <ErrorMessage
                        name="logradouro"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>
            <div className="col-12">
                Município
                <div>
                    <DataSource apiPath="components_data/munid_option_list">
                        {({
                            response,
                        }) => (
                            <>
                                <Dropdown
                                    filter
                                    name="munid"
                                    optionLabel="label"
                                    optionValue="value"
                                    value={
                                        formik
                                            .values
                                            .munid
                                    }
                                    onChange={
                                        formik.handleChange
                                    }
                                    options={
                                        response
                                    }
                                    label="Município"
                                    placeholder="Selecione um valor ..."
                                    className={inputClassName(
                                        formik
                                            ?.errors
                                            ?.munid
                                    )}
                                />
                                <ErrorMessage
                                    name="munid"
                                    component="span"
                                    className="p-error"
                                />
                            </>
                        )}
                    </DataSource>
                </div>
            </div>


            <div className="md:col-4 col-12">
                Função *
                <MultiSelectGroup
                    name="funcaoid"
                    multiSelect={
                        false
                    }
                    CurrentData={
                        formik.values.funcaoid
                    }
                    onChange={(
                        e
                    ) =>
                        (formik.values.funcaoid = e.value)
                    }
                    endpoint="components_data/funcaoid_option_list"
                    fields={{
                        label: "label",
                        value: "value",
                    }}
                />
            </div>

            <div className="md:col-4 col-12">
                Setor *
                <MultiSelectGroup
                    name="setorid"
                    multiSelect={
                        false
                    }
                    CurrentData={
                        formik.values.setorid
                    }
                    onChange={(
                        e
                    ) =>
                    ( formik.values.setorid = e.value )
                    }
                    endpoint="components_data/setorid_option_list"
                    fields={{
                        label: "label",
                        value: "value",
                    }}
                />
            </div>

            <div className="md:col-4 col-12">
                Centro de Custo *
                <MultiSelectGroup
                    name="deptid"
                    multiSelect={
                        false
                    }
                    CurrentData={
                        formik.values.deptid
                    }
                    onChange={(
                        e
                    ) =>
                    (formik.values.deptid =
                        e.value)
                    }
                    endpoint="components_data/deptid_option_list"
                    fields={{
                        label: "label",
                        value: "value",
                    }}
                />
            </div>

            <div className="md:col-12 col-12">
                GHE *
                <MultiSelectGroup
                    name="grupoids"
                    multiSelect={
                        true
                    }
                    CurrentData={
                        formik.values.grupoids
                    }
                    onChange={(
                        e
                    ) =>
                    (formik.values.grupoids =
                        e.value)
                    }
                    endpoint="grupogestor/list"
                    fields={{
                        label: "nome",
                        value: "id",
                    }}
                />
            </div>

            <div className="col-12">
                Mail *
                <div>
                    <InputText
                        name="mail"
                        onChange={
                            formik.handleChange
                        }
                        value={
                            formik
                                .values
                                .mail
                        }
                        label="Mail"
                        type="email"
                        placeholder="Entrar Mail"
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.mail
                        )}
                    />
                    <ErrorMessage
                        name="mail"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>
            <div className="md:col-4 col-12">
                Data Admissao*
                <div>
                    <Calendar
                        name="dataadmissao"
                        showButtonBar
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.dataadmissao
                        )}
                        dateFormat="yy-mm-dd"
                        value={
                            formik
                                .values
                                .dataadmissao
                        }
                        onChange={
                            formik.handleChange
                        }
                        showIcon
                    />
                    <ErrorMessage
                        name="dataadmissao"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>
            <div className="md:col-4 col-12">
                Data Demissao*
                <div>
                    <Calendar
                        name="datademissao"
                        showButtonBar
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.datademissao
                        )}
                        dateFormat="yy-mm-dd"
                        value={
                            formik
                                .values
                                .datademissao
                        }
                        onChange={
                            formik.handleChange
                        }
                        showIcon
                    />
                    <ErrorMessage
                        name="datademissao"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>

            <div className="col-12">
                Foto
                <div>
                    <div
                        className={inputClassName(
                            formik
                                ?.errors
                                ?.foto
                        )}
                    >
                        <Uploader
                            name="foto"
                            showUploadedFiles
                            value={
                                formik
                                    .values
                                    .foto
                            }
                            uploadPath="fileuploader/upload/foto"
                            onChange={(
                                paths
                            ) =>
                                formik.setFieldValue(
                                    "foto",
                                    paths
                                )
                            }
                            fileLimit={
                                1
                            }
                            maxFileSize={
                                3
                            }
                            accept=".jpg,.png,.gif,.jpeg"
                            multiple={
                                false
                            }
                            label="Escolha arquivos ou solte-os aqui"
                            onUploadError={(
                                errMsg
                            ) =>
                                app.flashMsg(
                                    "Upload error",
                                    errMsg,
                                    "error"
                                )
                            }
                        />
                    </div>
                    <ErrorMessage
                        name="foto"
                        component="span"
                        className="p-error"
                    />
                </div>
            </div>

            <div className="md:col-4 col-12">
                <div className="flex align-items-center">
                    <Checkbox
                        onChange={(e) => {
                            setCheckActive(e.checked);
                            formik.values.active = e.checked;
                            console.log(formik.values);
                        }}
                        checked={CheckActive}
                    />
                    <label className="ml-2">
                        Ativo?
                    </label>
                </div>
            </div>

            <div className="md:col-4 col-12">
                <div className="flex align-items-center">
                    <Checkbox
                        checked={
                            CheckGestor
                        }
                        onChange={(
                            e
                        ) =>
                            setCheckGestor(
                                e.checked
                            )
                        }
                    />
                    <label className="ml-2">
                        Gestor?
                    </label>
                </div>
            </div>
        </div>

    );
};

export default FuncionarioForm;