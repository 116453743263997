import React, { useState, useEffect, useContext } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CanView } from "components/Can";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ExportPageData } from "components/ExportPageData";
import { FilterTags } from "components/FilterTags";
import { InputText } from "primereact/inputtext";
import { PageRequestError } from "components/PageRequestError";
import { Paginator } from "primereact/paginator";
import { ProgressSpinner } from "primereact/progressspinner";
import { SplitButton } from "primereact/splitbutton";
import { Title } from "components/Title";
import ProdutosAddPage from "pages/produtos/Add";
import ProdutosEditPage from "pages/produtos/Edit";
import useApp from "hooks/useApp";
import useUtils from "hooks/useUtils";
import useListPage from "hooks/useListPage";
import MasterDetailPages from "./MasterDetailPages";
import { LockedFrag } from "components/Fragments/ComponentsFrag";
import StatusBarIcons from "components/StatusBardIcons";

const ProdutosListPage = (props) => {
    const app = useApp();
    const utils = useUtils();
    const { empid, setempid } = useApp();

    const filterSchema = {
        search: {
            tagTitle: "Search",
            value: "",
            valueType: "single",
            options: [],
        },
    };

    const statusIcons = [
        { icon: 'pi pi-lock', color:'yellow', hint: 'Bloqueado' }, // Font Awesome example
        { icon: 'pi pi-lock-open', color:'white', hint: 'Liberado' },
        { icon: 'pi pi-cloud-download', color:'yellow', hint: 'Importado / Integrador' },
        { icon: 'pi pi-file-edit', color:'white', hint: 'Digitação Manual' }
    ];

    const pageController = useListPage(props, filterSchema);
    const filterController = pageController.filterController;
    const {
        records,
        pageReady,
        loading,
        selectedItems,
        apiUrl,
        sortBy,
        sortOrder,
        apiRequestError,
        setSelectedItems,
        getPageBreadCrumbs,
        onSort,
        deleteItem,
        pagination,
    } = pageController;
    const { filters, setFilterValue } = filterController;
    const {
        totalRecords,
        totalPages,
        recordsPosition,
        firstRow,
        limit,
        onPageChange,
    } = pagination;

    function ActionButton2(data) {
        return (
            <div className="p-buttonset">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-info p-button-text"
                    onClick={() => app.openPageDialog(
                        <ProdutosEditPage
                            isSubPage
                            apiPath={`/produtos/edit/${data.id}`}
                        />,
                        { closeBtn: true }
                    )}
                    tooltip="Editar"
                    tooltipOptions={{ position: 'top' }}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button-text"
                    onClick={() => deleteItem(data.id)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: 'top' }}
                />
            </div>
        );
    }

    function ActionButton(data) {
        const items = [
            {
                label: "Editar",
                command: (event) => {
                    app.openPageDialog(
                        <ProdutosEditPage
                            isSubPage
                            apiPath={`/produtos/edit/${data.id}`}
                        />,
                        { closeBtn: true }
                    );
                },
                icon: "pi pi-pencil",
            },
            {
                label: "Excluir",
                command: (event) => {
                    deleteItem(data.id);
                },
                icon: "pi pi-trash",
            },
        ];
        return (
            <SplitButton
                dropdownIcon="pi pi-bars"
                className="dropdown-only p-button-text p-button-plain"
                model={items}
            />
        );
    }
    //alert(app.auth.user.prodcad);
    function CustoTemplate(data) {
        if (data) {
            return <>{app.FmtCurrChip({ value: data.custo })}</>;
        }
    }

    function IntegracaoTemplate(data) {
        if (data) {
            return (
                <div style={{ textAlign: 'center' }}>
                    {data.integracao === true ? (
                        <i className="pi pi-file-edit" style={{ color: 'white', fontSize: '1.5em' }}></i>
                    ) : (
                        <i className="pi pi-cloud-download" style={{ color: 'yellow', fontSize: '1.5em' }}></i>
                    )}
                </div>
            );
        }
    }

    function EstoqueTemplate(data) {
        if (data) {
            return <>{app.FmtQtdChip({ value: data.estoque })}</>;
        }
    }

    function EstoqueLocalTemplate(data) {
        if (data) {
            return <>{app.FmtQtdChip({ value: data.estoquelocal })}</>;
        }
    }

    function IdTemplate(data) {
        if (data) {
            return <>{data.grupos_id}</>;
        }
    }
    function EmpidTemplate(data) {
        if (data) {
            return <>{data.grupos_empid}</>;
        }
    }
    function NomeTemplate(data) {
        if (data) {
            return <>{data.grupos_nome}</>;
        }
    }
    const OnClickFichaProd = (param) => {
        if (param) {
            var paramns = { id: 4, filter: `produtos|id|${param.id}|0|0` };
            app.openPageDialog(<app.PrintPreview paramns={paramns} />, {
                maximized: true,
                closeBtn: true,
                width: "80vw",
            });
        }
    };

    function TemplateFichaProd(data) {
        if (data) {
            return <app.PrintButton onClick={() => OnClickFichaProd(data)} />;
        }
    }

    function MasterDetailBtnTemplate(data) {
        if (data) {
            return (
                <>
                    <Button
                        className="p-button-text"
                        onClick={() =>
                            app.openPageDialog(
                                <MasterDetailPages masterRecord={data} />,
                                { closeBtn: true }
                            )
                        }
                        label=""
                        icon="pi pi-caret-down"
                    />
                </>
            );
        }
    }
    function PageLoading() {
        if (loading) {
            return (
                <>
                    <div className="flex align-items-center justify-content-center text-gray-500 p-3">
                        <div>
                            <ProgressSpinner
                                style={{ width: "50px", height: "50px" }}
                            />{" "}
                        </div>
                        <div className="font-bold text-lg">Carregando...</div>
                    </div>
                </>
            );
        }
    }
    function EmptyRecordMessage() {
        if (pageReady && !records.length) {
            return (
                <div className="text-lg mt-3 p-3 text-center text-400 font-bold">
                    Nenhum Registro Encontrado
                </div>
            );
        }
    }
    function MultiDelete() {
        if (selectedItems.length) {
            return (
                <div className="m-2 flex-grow-0">
                    <Button
                        onClick={() => deleteItem(selectedItems)}
                        icon="pi pi-trash"
                        className="p-button-danger"
                        title="Excluir selecionado"
                    />
                </div>
            );
        }
    }
    function ExportData() {
        if (props.exportData && records.length) {
            const downloadFileName = `${utils.dateNow()}-produtos`;
            return (
                <div className="m-2">
                    <ExportPageData
                        csv={false}
                        pageUrl={apiUrl}
                        downloadFileName={downloadFileName}
                        butonLabel="Exportar"
                        tooltip="Exportar"
                        buttonIcon="pi pi-print"
                    />
                </div>
            );
        }
    }
    function PagerControl() {
        if (props.paginate && totalPages > 1) {
            const pagerReportTemplate = {
                layout: pagination.layout,
                CurrentPageReport: (options) => {
                    return (
                        <>
                            <span className="text-sm text-gray-500 px-2">
                                Registros{" "}
                                <b>
                                    {recordsPosition} do {options.totalRecords}
                                </b>
                            </span>
                        </>
                    );
                },
            };
            return (
                <div className="flex-grow-1">
                    <Paginator
                        first={firstRow}
                        rows={limit}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        template={pagerReportTemplate}
                    />
                </div>
            );
        }
    }
    function PageActionButtons() {
        return (
            <div className="flex flex-wrap">
                <MultiDelete />
                <ExportData />
            </div>
        );
    }

    function PageFooter() {
        if (pageReady && props.showFooter) {
            return (
                <div className="flex flex-wrap">
                    <PageActionButtons />
                    <PagerControl />
                </div>
            );
        }
    }
    function PageBreadcrumbs() {
        if (props.showBreadcrumbs) {
            const items = getPageBreadCrumbs();
            return (
                items.length > 0 && (
                    <BreadCrumb className="mb-3" model={items} />
                )
            );
        }
    }
    if (apiRequestError) {
        return <PageRequestError error={apiRequestError} />;
    }
    return (
        <main id="ProdutosListPage" className="main-page">
            {props.showHeader && (
                <section className="page-section mb-3">
                    <div className="container-fluid">
                        <div className="grid justify-content-between align-items-center">
                            <div className="col ">
                                <Title
                                    title="Produtos"
                                    titleClass="text-2xl text-primary font-bold"
                                    subTitleClass="text-500"
                                    separator={false}
                                />
                            </div>
                            {app.auth.user.prodcad && (
                                <div className="col-fixed ">
                                    <CanView pagePath="produtos/add">
                                        <Button
                                            label="Adicionar novo"
                                            icon="pi pi-plus"
                                            onClick={() =>
                                                app.openPageDialog(
                                                    <ProdutosAddPage
                                                        isSubPage
                                                        apiPath={`/produtos/add`}
                                                    />,
                                                    { closeBtn: true }
                                                )
                                            }
                                            className="p-button w-full bg-primary "
                                        />
                                    </CanView>
                                </div>
                            )}

                            {/* <div className="col-fixed " >
								<CanView pagePath="produtos/add">
									<Button label="Adicionar novo" icon="pi pi-plus" onClick={() => app.openPageDialog(<ProdutosAddPage isSubPage apiPath={`/produtos/add`} />, { closeBtn: true })} className="p-button w-full bg-primary " />
								</CanView>
							</div> */}

                            <div className="col-12 md:col-3 ">
                                <span className="p-input-icon-left w-full">
                                    <i className="pi pi-search" />
                                    {/* Analisar */}
                                    <InputText
                                        placeholder="Pesquisa"
                                        className="w-full"
                                        value={filters.search.value}
                                        onChange={(e) =>
                                            setFilterValue(
                                                "search",
                                                e.target.value
                                            )
                                        }
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            <section className="page-section ">
                <div className="container-fluid">
                    <div className="grid ">
                        <div className="col comp-grid">
                            <FilterTags filterController={filterController} />
                            <div>
                                <PageBreadcrumbs />
                                <StatusBarIcons title={"LEGENDA"} icons={statusIcons} />
                                <div className="page-records">
                                    <DataTable
                                        lazy={true}
                                        loading={loading}
                                        selectionMode="checkbox"
                                        selection={selectedItems}
                                        onSelectionChange={(e) =>
                                            setSelectedItems(e.value)
                                        }
                                        value={records}
                                        dataKey="id"
                                        sortField={sortBy}
                                        sortOrder={sortOrder}
                                        onSort={onSort}
                                        className=" p-datatable-sm"
                                        stripedRows={true}
                                        showGridlines={false}
                                        rowHover={true}
                                        responsiveLayout="stack"
                                        emptyMessage={<EmptyRecordMessage />}
                                    >
                                        {/*PageComponentStart*/}
                                        {/* <Column selectionMode="multiple" headerStyle={{ width: '2rem' }}></Column> */}
                                        {/* <Column headerStyle={{ width: '3rem' }} field="" body={MasterDetailBtnTemplate}></Column> */}
                                        {/* <Column headerStyle={{width:'5%'}} field="id" header="Id"  sortable={true} ></Column> */}
                                        {/* <Column headerStyle={{ width: '5%' }} field="codexterno" body={TemplateFichaProd} header="Imprimir" ></Column> */}
                                        <Column
                                            headerStyle={{ width: "5%" }}
                                            field="codexterno"
                                            header="COD"
                                            sortable={true}
                                        ></Column>
                                        <Column
                                            headerStyle={{ width: "5%" }}
                                            field="codfabrica"
                                            header="FAB"
                                            sortable={true}
                                        ></Column>
                                        <Column
                                            headerStyle={{ width: "5%" }}
                                            field="unidade"
                                            header="UND"
                                            sortable={true}
                                        ></Column>
                                        {/* <Column field="grupos_nome" header="Grupo" body={NomeTemplate} sortable={true} ></Column> */}
                                        <Column
                                            headerStyle={{ width: "75%" }}
                                            field="nome"
                                            header="Nome"
                                            sortable={true}
                                        ></Column>
                                        <Column
                                            headerStyle={{ width: "5%" }}
                                            field="custo"
                                            header="Custo"
                                            body={CustoTemplate}
                                            sortable={true}
                                        ></Column>
                                        <Column
                                            field="estoque"
                                            header="Estoque"
                                            body={EstoqueTemplate}
                                            sortable={true}
                                        ></Column>
                                        <Column
                                            field="estoquelocal"
                                            header="Estoque Cliente"
                                            body={EstoqueLocalTemplate}
                                            sortable={true}
                                        ></Column>
                                        <Column
                                            field="active"
                                            body={(e) => LockedFrag(e.active)}
                                            // body={ActiveTemplate}
                                            //sortable={true}
                                        ></Column>
                                        <Column
                                            field="integracao"
                                            // header="Integracao"
                                            body={IntegracaoTemplate}
                                            //sortable={true}
                                        ></Column>
                                        <Column
                                            headerStyle={{ width: "20%" }}
                                            headerClass="text-center"
                                            body={ActionButton}
                                        ></Column>
                                        {/*PageComponentEnd*/}
                                    </DataTable>
                                </div>
                                <PageFooter />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
ProdutosListPage.defaultProps = {
    primaryKey: "id",
    pageName: "produtos",
    apiPath: "produtos/index",
    routeName: "produtoslist",
    msgBeforeDelete: "Tem certeza de que deseja excluir este registro?",
    msgTitle: "Apagar registro",
    msgAfterDelete: "Registro excluído com sucesso",
    showHeader: true,
    showFooter: true,
    paginate: true,
    isSubPage: false,
    showBreadcrumbs: true,
    exportData: true,
    importData: false,
    keepRecords: false,
    multiCheckbox: true,
    search: "",
    fieldName: "empid",
    fieldValue: null,
    sortField: "",
    sortDir: "",
    pageNo: 1,
    limit: 10
};
export default ProdutosListPage;
